import { Component, OnInit } from '@angular/core';
import {CdkDynamicTable, CdkDynamicTableService} from "@datagrupo/dg-ng-util";
import {ActivatedRoute, Router} from "@angular/router";
import {HttpService} from "../../../../../services/http-service/http.service";
import {CATEGORIA} from "../../../../../core/endpoints";
import {FoodCategoriaEntity} from "../food-categoria.entity";

@Component({
  selector: 'app-food-categorias-main',
  templateUrl: './food-categorias-main.component.html',
  styleUrls: ['./food-categorias-main.component.scss']
})
export class FoodCategoriasMainComponent implements OnInit {

  public table: CdkDynamicTable.tableClass;

  constructor(
    private dynamicTable: CdkDynamicTableService,
    private router: Router,
    private activate: ActivatedRoute,
    private http: HttpService
  ) {
    this.table = dynamicTable.createByEntity(new FoodCategoriaEntity())
    this.table.controls.actions.setObject({
      editar: {
        name: 'Editar',
        action: (val: any) => router.navigate([val.id], { relativeTo: activate }),
        icon: 'bi bi-pencil-fill'
      },
      excluir: {
        name: 'Excluir',
        action: (val: any) => {
          http.deleteById(CATEGORIA, val.id).subscribe(resp => {
            this.table.find()
          })
        },
        icon: 'bi bi-trash3'
      }
    })
  }

  ngOnInit(): void {
  }

}
