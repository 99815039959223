import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SiteAgendamentoHomeComponent } from './site-agendamento-home/site-agendamento-home.component';
import {MatIconModule} from "@angular/material/icon";



@NgModule({
  declarations: [
    SiteAgendamentoHomeComponent
  ],
    imports: [
        CommonModule,
        MatIconModule
    ]
})
export class SiteAgendamentoModule { }
