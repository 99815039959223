import {AbstractEntity2, CrudEntity} from "@datagrupo/dg-crud";
import {DynamicTableEntity, DynamicColumn} from "@datagrupo/dg-ng-util";
import {environment} from "../../../../../environments/environment";
import {FOOD_PRODUTO} from "../../../../core/endpoints";
import {FoodCategoriaEntity} from "../food-categorias/food-categoria.entity";
import {FoodProdutoComplementos} from "./food-produto-complementos";
import {EstabelecimentoEntity} from "../../estabelecimento/estabelecimento.entity";


@CrudEntity({
  api: {
    path: environment.apiUrl,
    context: FOOD_PRODUTO
  },
})
@DynamicTableEntity({
  api: {
    path: environment.apiUrl,
    context: FOOD_PRODUTO
  }
})
export class FoodProdutoEntity extends AbstractEntity2 {

  constructor(
    nome?: string,
    valor?: string,
    imageUrl?: string,
    categoria?: FoodCategoriaEntity,
    complementos?: FoodProdutoComplementos[],
    empresa?: Partial<EstabelecimentoEntity>
  ) {
    super();
    this.nome = nome;
    this.valor = valor;
    this.categoria = categoria;
    this.empresa = empresa;
    this.imageUrl = imageUrl;
    this.complementos = Array.isArray(complementos) ? complementos : [];
  }

  @DynamicColumn({ headerName: 'nome' })
  nome: string | undefined;
  @DynamicColumn({ headerName: 'valor' })
  valor: string | undefined;
  @DynamicColumn({ headerName: 'categorias', resource: val => val?.nome || '--' })
  categoria: FoodCategoriaEntity | undefined;

  imageUrl: string | undefined;
  complementos: FoodProdutoComplementos[] = []
  empresa: Partial<EstabelecimentoEntity> | undefined
}
