import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ColaboradorMainComponent } from './colaborador-main/colaborador-main.component';
import { ColaboradorInsertEditComponent } from './colaborador-insert-edit/colaborador-insert-edit.component';
import {CdkDynamicTableModule, DgPaginatorModule, DgTableModule} from "@datagrupo/dg-ng-util";
import {ReactiveFormsModule} from "@angular/forms";
import {DgCrudModule} from "@datagrupo/dg-crud";
import {RouterModule} from "@angular/router";



@NgModule({
  declarations: [
    ColaboradorMainComponent,
    ColaboradorInsertEditComponent
  ],
    imports: [
        CommonModule,
        CdkDynamicTableModule,
        DgTableModule,
        DgPaginatorModule,
        ReactiveFormsModule,
        DgCrudModule,
        RouterModule,
    ]
})
export class ColaboradorModule { }
