import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProdutosMainComponent } from './produtos-main/produtos-main.component';
import { ProdutosInsertEditComponent } from './produtos-insert-edit/produtos-insert-edit.component';
import {CdkDynamicTableModule, DgPaginatorModule, DgTableModule} from "@datagrupo/dg-ng-util";
import {ReactiveFormsModule} from "@angular/forms";
import {DgCrudModule} from "@datagrupo/dg-crud";
import {RouterModule} from "@angular/router";
import {MatSelectModule} from "@angular/material/select";



@NgModule({
  declarations: [
    ProdutosMainComponent,
    ProdutosInsertEditComponent
  ],
    imports: [
        CommonModule,
        DgPaginatorModule,
        DgTableModule,
        CdkDynamicTableModule,
        ReactiveFormsModule,
        DgCrudModule,
        RouterModule,
        MatSelectModule
    ]
})
export class ProdutoModule { }
