import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoginComponent } from './login/login.component';
import {SiteAgendamentoModule} from "./site-agendamento/site-agendamento.module";
import {RouterModule} from "@angular/router";
import { PublicHomeComponent } from './public-home/public-home.component';
import {ReactiveFormsModule} from "@angular/forms";
import { SignupComponent } from './signup/signup.component';
import {NgxMaskModule} from "ngx-mask";
import {MatStepperModule} from "@angular/material/stepper";



@NgModule({
  declarations: [
    LoginComponent,
    PublicHomeComponent,
    SignupComponent
  ],
  imports: [
    CommonModule,
    SiteAgendamentoModule,
    RouterModule,
    ReactiveFormsModule,
    NgxMaskModule,
    MatStepperModule
  ]
})
export class PublicPagesModule { }
