<div class="container-fluid" style="padding: 0 4rem">

  <div class="row" *ngIf="statusPage != 'selecionar-servico'">
    <div class="col-2"></div>
    <div class="col-10">
      <mat-icon (click)="backStatusPage()">arrow_back</mat-icon>
    </div>
  </div>

  <div class="row">
    <div class="col-2">
      <h3>Categorias</h3>
      <ul class="list-categorias">
        <li>
          <span>Em destaque</span>
        </li>
        <li *ngFor="let item of listaCategorias">
          <span>{{ item.nome }}</span>
        </li>
      </ul>
    </div>

    <div class="col-6">
      <div class="container-fluid" *ngIf="statusPage == 'selecionar-servico'">
        <h3>lista de Serviços</h3>
        <div class="row" *ngFor="let item of listaServicos">
          <div class="card m-1" (click)="selectService(item)">
            <div class="card-body">
              <div class="row">
                <div class="col-1">
                  <input type="checkbox" [checked]="selectedService(item)">
                </div>
                <div class="col-11">
                  <h5 class="card-title">{{item.nome}}</h5>
                  <p class="card-text">With supporting text below as a natural lead-in to additional content.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="container-fluid" *ngIf="statusPage == 'selecionar-colaborador'">
        <h3>Selecionar Proficional</h3>
        <div class="row" *ngFor="let item of listaColaboradores" (click)="selecionarColaborador(item)">
          <div class="card m-1">
            <div class="card-body">
              <h5 class="card-title">{{item.nome}}</h5>
            </div>
          </div>
        </div>
      </div>
      <div class="container-fluid" *ngIf="statusPage == 'agendar'">
        <h3>Agendar</h3>
        <div class="row" *ngFor="let item of horasDisponiveis" (click)="agendar(item)">
          <div class="card m-1">
            <div class="card-body">
              <h5 class="card-title">{{item}}</h5>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="col-4">
      <h3>carrinho</h3>
      <div class="card mt-2">
        <div class="card-body">
          <ul class="list-selecionados">
            <li *ngFor="let item of listaServicosSelecionados">
              <span class="mr-auto">{{item.nome}}</span>
              <span class="ms-auto">{{item.valor}}</span>
            </li>
          </ul>
        </div>
        <div class="card-footer">
          <ul class="list-selecionados">
            <li *ngIf="listaServicosSelecionados.length > 0">
              <span class="mr-auto">Subtotal</span>
              <span class="ms-auto">{{calcSubTotal()}}</span>
            </li>
            <li *ngIf="listaServicosSelecionados.length > 0">
              <span class="mr-auto">Desconto</span>
              <span class="ms-auto">{{calcDesconto()}}</span>
            </li>
            <li *ngIf="listaServicosSelecionados.length > 0">
              <span class="mr-auto">Total</span>
              <span class="ms-auto">{{calcTotal()}}</span>
            </li>
            <li *ngIf="listaServicosSelecionados.length == 0">
              <span class="mr-auto">Total</span>
              <span class="ms-auto">0,00</span>
            </li>
          </ul>
        </div>
        <div class="card-footer" *ngIf="statusPage == 'selecionar-servico' && listaServicosSelecionados.length > 0">
          <button class="btn btn-success" (click)="statusPage = 'selecionar-colaborador'">Agendar Agora</button>
        </div>
      </div>
    </div>
  </div>
</div>
