<div class="container">
  <form [formGroup]="form">
    <div class="row">
      <div class="col-12">
        <label class="form-label"></label>
        <input class="form-control" name="email" formControlName="username">
      </div>
      <div class="col-12">
        <label class="form-label"></label>
        <input class="form-control" name="password" formControlName="password">
      </div>
    </div>
  </form>
  <div class="row">
    <div class="col-12">
      <button (click)="logar()" class="btn btn-success">Entrar</button>
    </div>
  </div>
</div>
