import { Component, OnInit } from '@angular/core';
import {AbstractInsertEdit2, InsertEditConfig2} from "@datagrupo/dg-crud";
import {ColaboradorEntity} from "../colaborador.entity";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {SessionService} from "../../../../services/session/session.service";

@Component({
  selector: 'app-colaborador-insert-edit',
  templateUrl: './colaborador-insert-edit.component.html',
  styleUrls: ['./colaborador-insert-edit.component.scss']
})
export class ColaboradorInsertEditComponent extends AbstractInsertEdit2<ColaboradorEntity> implements OnInit {

  rootEntity = new ColaboradorEntity();

  form = new FormGroup({
    nome: new FormControl('', [Validators.required]),
    cargo: new FormControl('', [Validators.required]),
    email: new FormControl('', [Validators.required]),
    telefone: new FormControl('', [Validators.required]),
  });

  constructor(private config: InsertEditConfig2, private session: SessionService) {
    super(config);
  }

  override ngOnInit(): void {
    super.ngOnInit();
  }

  override afterFetchEntity(entityData: any) {
    this.form.patchValue({...entityData});
  }

  override beforeSaveEntity(): boolean {
    if (this.form.invalid) {
      this.form.markAllAsTouched();
      return false;
    }

    const form = this.form.value;

    this.entity = {
      ...this.entity,
      estabelecimento: {id: this.session.user.estabalecimento},
      ...form
    }

    return true;
  }
}
