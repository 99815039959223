import { Component, OnInit } from '@angular/core';
import {AbstractInsertEdit2, InsertEditConfig2} from "@datagrupo/dg-crud";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {ClienteEntity} from "../cliente.entity";

@Component({
  selector: 'app-clientes-insert-edit',
  templateUrl: './clientes-insert-edit.component.html',
  styleUrls: ['./clientes-insert-edit.component.scss']
})
export class ClientesInsertEditComponent extends AbstractInsertEdit2<ClienteEntity> implements OnInit {

  rootEntity = new ClienteEntity();

  form = new FormGroup({
    nome: new FormControl('', [Validators.required]),
    telefone: new FormControl('', [Validators.required]),
    email: new FormControl(''),
  })

  constructor(public config: InsertEditConfig2) {
    super(config)
  }

  override ngOnInit(): void {
    super.ngOnInit();
  }

  override afterFetchEntity(entityData: any) {
    this.form.patchValue(entityData)
  }

  override beforeSaveEntity(): boolean {
    if (this.form.invalid) {
      this.form.markAllAsTouched();
      return false;
    }

    const form = this.form.value;

    this.entity = {
      ...this.entity,
      ...form
    }

    return true;
  }

}
