<div class="card card-pedido-esteira card-content-status-pedido-esteira" [class.selected]="!!data['selectedItem']">
  <div
    class="card-header d-inline-flex justify-content-between"
    cdkDrag
  >
    <h6 class="card-title">{{data?.entregador?.nome || '--'}}</h6>
  </div>
  <div class="card-body">
    <ul class="list-group">
      <li class="list-group-item" *ngFor="let item of (data.vendas || [])">
        <pre>{{item?.cliente?.nome}}</pre>
      </li>
    </ul>
  </div>
  <div class="card-footer d-inline-flex justify-content-between">
    <button *ngIf="data?.status == 'PREPARO_ENTREGA'" [disabled]="data?.selectedItem" (click)="open.emit()" class="btn btn-info btn-sm">Abrir</button>
    <button *ngIf="data?.status == 'ENTREGA_ANDAMENTO'" [disabled]="data?.selectedItem" (click)="modalEntregaAndamento.open(data)" class="btn btn-info btn-sm">Abrir</button>
  </div>
</div>



<modal-esteira-entrega-andamento #modalEntregaAndamento>
</modal-esteira-entrega-andamento>
