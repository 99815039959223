import { Component, OnInit } from '@angular/core';
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {AbstractInsertEdit2, InsertEditConfig2} from "@datagrupo/dg-crud";
import {EstabelecimentoEntity} from "../estabelecimento.entity";
import {DiasTrabalhadosEntity} from "../../../../shared/entitys/dias-trabalhados.entity";
import {HttpHelpersService} from "../../../../services/http-helpers/http-helpers.service";

@Component({
  selector: 'app-estabelecimento-insert-edit',
  templateUrl: './estabelecimento-insert-edit.component.html',
  styleUrls: ['./estabelecimento-insert-edit.component.scss']
})
export class EstabelecimentoInsertEditComponent extends AbstractInsertEdit2<EstabelecimentoEntity> implements OnInit {

  rootEntity = new EstabelecimentoEntity();

  public form = new FormGroup({
    nome: new FormControl('', [Validators.required]),
    telefone: new FormControl('', [Validators.required]),
    instagram: new FormControl('', [Validators.required]),
    facebook: new FormControl('', [Validators.required]),
    cep: new FormControl('', [Validators.required]),
    logradouro: new FormControl('', [Validators.required]),
    numero: new FormControl('', [Validators.required]),
    link: new FormControl('', [Validators.required]),
    complemento: new FormControl(''),
    bairro: new FormControl(''),
    cidade: new FormControl(''),
    estado: new FormControl(''),

    domingoStatus: new FormControl(false),
    domingoAbertura: new FormControl(''),
    domingoFechamento: new FormControl(''),

    segundaStatus: new FormControl(false),
    segundaAbertura: new FormControl(''),
    segundaFechamento: new FormControl(''),

    tercaStatus: new FormControl(false),
    tercaAbertura: new FormControl(''),
    tercaFechamento: new FormControl(''),

    quartaStatus: new FormControl(false),
    quartaAbertura: new FormControl(''),
    quartaFechamento: new FormControl(''),

    quintaStatus: new FormControl(false),
    quintaAbertura: new FormControl(''),
    quintaFechamento: new FormControl(''),

    sextaStatus: new FormControl(false),
    sextaAbertura: new FormControl(''),
    sextaFechamento: new FormControl(''),

    sabadoStatus: new FormControl(false),
    sabadoAbertura: new FormControl(''),
    sabadoFechamento: new FormControl(''),
  })

  constructor(public config: InsertEditConfig2, private httpHelper: HttpHelpersService) {
    super(config);

    this.form.controls['domingoStatus'].valueChanges.subscribe((resp: any) => {
      this.form.controls['domingoAbertura'][!!resp ? 'enable' : 'disable']()
      this.form.controls['domingoFechamento'][!!resp ? 'enable' : 'disable']()
    })
    this.form.controls['segundaStatus'].valueChanges.subscribe((resp: any) => {
      this.form.controls['segundaAbertura'][!!resp ? 'enable' : 'disable']()
      this.form.controls['segundaFechamento'][!!resp ? 'enable' : 'disable']()
    })
    this.form.controls['tercaStatus'].valueChanges.subscribe((resp: any) => {
      this.form.controls['tercaAbertura'][!!resp ? 'enable' : 'disable']()
      this.form.controls['tercaFechamento'][!!resp ? 'enable' : 'disable']()
    })
    this.form.controls['quartaStatus'].valueChanges.subscribe((resp: any) => {
      this.form.controls['quartaAbertura'][!!resp ? 'enable' : 'disable']()
      this.form.controls['quartaFechamento'][!!resp ? 'enable' : 'disable']()
    })
    this.form.controls['quintaStatus'].valueChanges.subscribe((resp: any) => {
      this.form.controls['quintaAbertura'][!!resp ? 'enable' : 'disable']()
      this.form.controls['quintaFechamento'][!!resp ? 'enable' : 'disable']()
    })
    this.form.controls['sextaStatus'].valueChanges.subscribe((resp: any) => {
      this.form.controls['sextaAbertura'][!!resp ? 'enable' : 'disable']()
      this.form.controls['sextaFechamento'][!!resp ? 'enable' : 'disable']()
    })
    this.form.controls['sabadoStatus'].valueChanges.subscribe((resp: any) => {
      this.form.controls['sabadoAbertura'][!!resp ? 'enable' : 'disable']()
      this.form.controls['sabadoFechamento'][!!resp ? 'enable' : 'disable']()
    })
  }

  override ngOnInit(): void {
    super.ngOnInit();
  }

  override afterFetchEntity(entityData: any) {
    this.form.patchValue({...entityData});
    this.getCep()
  }

  override initNewEntity() {
    this.form.patchValue({
      segundaStatus: true,
      tercaStatus: true,
      quartaStatus: true,
      quintaStatus: true,
      sextaStatus: true,
      sabadoStatus: true,
    })

    this.getCep()
  }

  override beforeSaveEntity(): boolean {
    if (this.form.invalid) {
      this.form.markAllAsTouched();
      return false;
    }

    const form = this.form.value;

    this.entity = {
      ...this.entity,
      ...form,
    }

    return true;
  }


  getCep() {
    this.form.controls['cep'].valueChanges.subscribe((resp: any) => {
      this.httpHelper.getCep(resp, value => {
        this.form.patchValue({
          logradouro: value.logradouro,
          bairro: value.bairro,
          cidade: value.localidade,
          estado: value.uf,
        })
      })
    })
  }
}
