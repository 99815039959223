import { Component, OnInit } from '@angular/core';
import {
  CdkDynamicTable,
  CdkDynamicTableService
} from "@datagrupo/dg-ng-util";
import {ServicosAgendaveisEntity} from "../servicos-agendaveis.entity";
import {ActivatedRoute, Router} from "@angular/router";
import {HttpService} from "../../../../services/http-service/http.service";
import {SERVICOS_AGENDAVEIS} from "../../../../core/endpoints";

@Component({
  selector: 'app-servicos-agendaveis-main',
  templateUrl: './servicos-agendaveis-main.component.html',
  styleUrls: ['./servicos-agendaveis-main.component.scss']
})
export class ServicosAgendaveisMainComponent implements OnInit {

  public table: CdkDynamicTable.tableClass;

  constructor(
    private dynamicTable: CdkDynamicTableService,
    private router: Router,
    private activate: ActivatedRoute,
    private http: HttpService
  ) {
    this.table = dynamicTable.createByEntity(new ServicosAgendaveisEntity())
    this.table.controls.actions.setObject({
      editar: {
        name: 'Editar',
        action: (val: any) => router.navigate([val.id], { relativeTo: activate }),
        icon: 'bi bi-pencil-fill'
      },
      excluir: {
        name: 'Excluir',
        action: (val: any) => {
          http.deleteById(SERVICOS_AGENDAVEIS, val.id).subscribe(resp => {
            this.table.find()
          })
        },
        icon: 'bi bi-trash3'
      }
    })
  }

  ngOnInit(): void {
  }

}
