<div class="container mt-4">
  <div class="row">
    <div class="col-12">
      <ul class="nav nav-tabs">
        <li class="nav-item">
          <span
            class="nav-link active"
            [class.active]="view == 'marca'"
            (click)="view = 'marca'"
          >
            Sobre a sua marca
          </span>
        </li>
        <li class="nav-item">
          <span
            class="nav-link"
            [class.active]="view == 'configuracoes'"
            (click)="view = 'configuracoes'"
          >
            Configurações
          </span>
        </li>
<!--        <li class="nav-item">-->
<!--          <span-->
<!--            class="nav-link"-->
<!--            [class.active]="view == 'layout'"-->
<!--            (click)="view = 'layout'"-->
<!--          >-->
<!--            Layout-->
<!--          </span>-->
<!--        </li>-->
      </ul>
    </div>
  </div>
</div>

<div class="container mt-4" [hidden]="view != 'marca'">
  <div class="row">
    <div class="col-12">
      <div class="card">
        <div class="card-header">
          <h4 class="card-title">Empresa</h4>
        </div>
        <div class="card-body">
          <form [formGroup]="formEmpresa">
            <div class="row">
              <div class="col-4">
                img
              </div>
              <div class="col-8">
                <div class="row">
                  <div class="col-6">
                    <label class="form-label">Nome</label>
                    <input name="estabelecimento" class="form-control" formControlName="nome">
                  </div>
                  <div class="col-6">
                    <label class="form-label">Link</label>
                    <input name="estabelecimento-link" class="form-control" formControlName="link">
                  </div>
                </div>
                <div class="row">
                  <div class="col-12">
                    <label class="form-label">Discreva a sua empresa</label>
                    <textarea class="form-control"></textarea>
                  </div>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-xl-4 col-lg-4 col-md-6 col-sm-12">
                <label class="form-label">CEP</label>
                <input name="cep" class="form-control" mask="00.000-000" formControlName="cep">
              </div>
              <div class="col-cl-8 col-lg-8 col-md-6 col-sm-12">
                <label class="form-label">Logradouro</label>
                <input class="form-control" formControlName="logradouro">
              </div>
              <div class="col-2">
                <label class="form-label">Numero</label>
                <input name="numero" class="form-control" formControlName="numero">
              </div>
              <div class="col-2">
                <label class="form-label">complemento</label>
                <input class="form-control" formControlName="complemento">
              </div>
              <div class="col-4">
                <label class="form-label">Bairro</label>
                <input class="form-control" formControlName="bairro">
              </div>
              <div class="col-2">
                <label class="form-label">Cidade</label>
                <input class="form-control" formControlName="cidade">
              </div>
              <div class="col-2">
                <label class="form-label">Estado</label>
                <input class="form-control" formControlName="estado">
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="container mt-4" [hidden]="view != 'configuracoes'">
  <div class="row">
    <div class="col-12">
      <div class="card">
        <div class="card-header">
          <h4 class="card-title">Dias de funcionamento na semana</h4>
        </div>
        <div class="card-body">
          <form [formGroup]="formHorariosFuncionamento">
            <div class="selecao-dias-trabalhados-semana">
              <div class="card" [class.disabled]="!(!!formHorariosFuncionamento.value?.domingoStatus)">
                <div class="card-header">
                  <div class="form-check">
                    <input name="diaFuncionamento" formControlName="domingoStatus" class="form-check-input" type="checkbox" value="" id="dia-domingo">
                    <label class="form-check-label" for="dia-domingo">Ddomingo</label>
                  </div>
                </div>
                <div class="card-body">
                  <div>
                    <label class="form-label" style="font-size: 9pt">Abertura</label>
                    <input name="diaFuncionamento" formControlName="domingoAbertura" class="form-control" mask="00:00" placeholder="08:45">
                  </div>
                  <div>
                    <label class="form-label" style="font-size: 9pt">Fechamento</label>
                    <input name="diaFuncionamento" formControlName="domingoFechamento" class="form-control" mask="00:00" placeholder="17:45">
                  </div>
                </div>
              </div>

              <div class="card" [class.disabled]="!(!!formHorariosFuncionamento.value?.segundaStatus)">
                <div class="card-header">
                  <div class="form-check">
                    <input name="diaFuncionamento"  formControlName="segundaStatus" class="form-check-input" type="checkbox" value="" id="dia-segunda">
                    <label class="form-check-label" for="dia-segunda">Segunda</label>
                  </div>
                </div>
                <div class="card-body">
                  <div>
                    <label class="form-label" style="font-size: 9pt">Abertura</label>
                    <input name="diaFuncionamento" formControlName="segundaAbertura" class="form-control" mask="00:00" placeholder="08:45">
                  </div>
                  <div>
                    <label class="form-label" style="font-size: 9pt">Fechamento</label>
                    <input  formControlName="segundaFechamento" class="form-control" mask="00:00" placeholder="17:45">
                  </div>
                </div>
              </div>

              <div class="card" [class.disabled]="!(!!formHorariosFuncionamento.value?.tercaStatus)">
                <div class="card-header">
                  <div class="form-check">
                    <input name="diaFuncionamento" formControlName="tercaStatus" class="form-check-input" type="checkbox" value="" id="dia-terça">
                    <label class="form-check-label" for="dia-terça">Terça</label>
                  </div>
                </div>
                <div class="card-body">
                  <div>
                    <label class="form-label" style="font-size: 9pt">Abertura</label>
                    <input name="diaFuncionamento" formControlName="tercaAbertura" class="form-control" mask="00:00" placeholder="08:45">
                  </div>
                  <div>
                    <label class="form-label" style="font-size: 9pt">Fechamento</label>
                    <input name="diaFuncionamento" formControlName="tercaFechamento" class="form-control" mask="00:00" placeholder="17:45">
                  </div>
                </div>
              </div>

              <div class="card" [class.disabled]="!(!!formHorariosFuncionamento.value?.quartaStatus)">
                <div class="card-header">
                  <div class="form-check">
                    <input name="diaFuncionamento" formControlName="quartaStatus" class="form-check-input" type="checkbox" value="" id="dia-quarta">
                    <label class="form-check-label" for="dia-quarta">Quarta</label>
                  </div>
                </div>
                <div class="card-body">
                  <div>
                    <label class="form-label" style="font-size: 9pt">Abertura</label>
                    <input name="diaFuncionamento" formControlName="quartaAbertura" class="form-control" mask="00:00" placeholder="08:45">
                  </div>
                  <div>
                    <label class="form-label" style="font-size: 9pt">Fechamento</label>
                    <input formControlName="quartaFechamento" class="form-control" mask="00:00" placeholder="17:45">
                  </div>
                </div>
              </div>

              <div class="card" [class.disabled]="!(!!formHorariosFuncionamento.value?.quintaStatus)">
                <div class="card-header">
                  <div class="form-check">
                    <input name="diaFuncionamento" formControlName="quintaStatus" class="form-check-input" type="checkbox" value="" id="dia-quinta">
                    <label class="form-check-label" for="dia-quinta">Quinta</label>
                  </div>
                </div>
                <div class="card-body">
                  <div>
                    <label class="form-label" style="font-size: 9pt">Abertura</label>
                    <input name="diaFuncionamento" formControlName="quintaAbertura" class="form-control" mask="00:00" placeholder="08:45">
                  </div>
                  <div>
                    <label class="form-label" style="font-size: 9pt">Fechamento</label>
                    <input name="diaFuncionamento" formControlName="quintaFechamento" class="form-control" mask="00:00" placeholder="17:45">
                  </div>
                </div>
              </div>

              <div class="card" [class.disabled]="!(!!formHorariosFuncionamento.value?.sextaStatus)">
                <div class="card-header">
                  <div class="form-check">
                    <input name="diaFuncionamento" formControlName="sextaStatus" class="form-check-input" type="checkbox" value="" id="dia-sexta">
                    <label class="form-check-label" for="dia-sexta">Sexta</label>
                  </div>
                </div>
                <div class="card-body">
                  <div>
                    <label class="form-label" style="font-size: 9pt">Abertura</label>
                    <input name="diaFuncionamento" formControlName="sextaAbertura" class="form-control" mask="00:00" placeholder="08:45">
                  </div>
                  <div>
                    <label class="form-label" style="font-size: 9pt">Fechamento</label>
                    <input name="diaFuncionamento" formControlName="sextaFechamento" class="form-control" mask="00:00" placeholder="17:45">
                  </div>
                </div>
              </div>

              <div class="card" [class.disabled]="!(!!formHorariosFuncionamento.value?.sabadoStatus)">
                <div class="card-header">
                  <div class="form-check">
                    <input name="diaFuncionamento" formControlName="sabadoStatus" class="form-check-input" type="checkbox" value="" id="dia-sabado">
                    <label class="form-check-label" for="dia-sabado">Sábado</label>
                  </div>
                </div>
                <div class="card-body">
                  <div>
                    <label class="form-label" style="font-size: 9pt">Abertura</label>
                    <input name="diaFuncionamento" formControlName="sabadoAbertura" class="form-control" mask="00:00" placeholder="08:45">
                  </div>
                  <div>
                    <label class="form-label" style="font-size: 9pt">Fechamento</label>
                    <input name="diaFuncionamento" formControlName="sabadoFechamento" class="form-control" mask="00:00" placeholder="17:45">
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>

  <div class="row mt-2">
    <div class="col-12">
      <div class="card">
        <div class="card-header">
          <h4 class="card-title">Forma de pagamento</h4>
          <p class="card-subtitle">Selecione as formas de pagamento</p>
        </div>
        <div class="card-body">

          <div class="card mb-3" style="height: 80px;">
            <div class="row g-0">
              <div class="col-xl-1 col-lg-1 col-md-1 col-sm-1 d-flex justify-content-center align-items-center">
                <input [checked]="isSelected('DINHEIRO')" (click)="clickSelect('DINHEIRO')" class="form-check"
                       type="checkbox">
              </div>
              <div class="col-xl-2 col-lg-2 col-md-2 col-sm-2">
                <img style="max-height: 70px" src="/assets/images/formas-pagamento/dinheiro.png"
                     class="img-fluid rounded-start" alt="Pix">
              </div>
              <div class="col-xl-8 col-lg-8 col-md-8 col-sm-8">
                <div class="card-body">
                  <h5 class="card-title">Dinheiro</h5>
                </div>
              </div>
            </div>
          </div>

          <div class="card mb-3" style="height: 80px;">
            <div class="row g-0">
              <div class="col-xl-1 col-lg-1 col-md-1 col-sm-1 d-flex justify-content-center align-items-center">
                <input [checked]="isSelected('PIX')" (click)="clickSelect('PIX')" class="form-check" type="checkbox">
              </div>
              <div class="col-xl-2 col-lg-2 col-md-2 col-sm-2">
                <img style="max-height: 70px" src="/assets/images/formas-pagamento/pix.png"
                     class="img-fluid rounded-start" alt="Pix">
              </div>
              <div class="col-xl-8 col-lg-8 col-md-8 col-sm-8">
                <div class="card-body">
                  <h5 class="card-title">Pix</h5>
                </div>
              </div>
            </div>
          </div>

          <div class="card mb-3" style="height: 80px;">
            <div class="row g-0">
              <div class="col-xl-1 col-lg-1 col-md-1 col-sm-1 d-flex justify-content-center align-items-center">
                <input [checked]="isSelected('CARTAO')" (click)="clickSelect('CARTAO')" class="form-check"
                       type="checkbox">
              </div>
              <div class="col-xl-2 col-lg-2 col-md-2 col-sm-2">
                <img style="max-height: 70px" src="/assets/images/formas-pagamento/cartoes.png"
                     class="img-fluid rounded-start" alt="Pix">
              </div>
              <div class="col-xl-8 col-lg-8 col-md-8 col-sm-8">
                <div class="card-body">
                  <h5 class="card-title">Debido/Crédito</h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="container mt-4" [hidden]="view != 'layout'">
<!--  <iframe style="width: 100%; height: 100vh" frameBorder="0" src="http://localhost:4300/client/habibs"></iframe>-->
</div>

<div class="page-footer page-footer-signup">
  <div class="container mb-2">
    <div class="row">
      <div class="col-12 d-inline-flex justify-content-center">
        <button (click)="save()" class="btn btn-success m-1">Salvar</button>
      </div>
    </div>
  </div>
</div>
<div style="margin-top: 6rem"></div>
