import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {PublicLayoutModule} from "./public/public-layout.module";
import {PrivateLayoutModule} from "./private/private-layout.module";
import {SiteAgendamentoModule} from "./site-agendamento/site-agendamento.module";



@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    PublicLayoutModule,
    PrivateLayoutModule,
    SiteAgendamentoModule
  ]
})
export class LayoutsModule { }
