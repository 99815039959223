import {Component, Input, OnInit} from '@angular/core';
import {FoodProdutoComplementos} from "../../food-produto-complementos";
import {FoodProdutoComplementosItemsEntity} from "../../food-produto-complementos-items.entity";
import {SessionService} from "../../../../../../services/session/session.service";
import {HttpService} from "../../../../../../services/http-service/http.service";
import {
  FOOD_PRODUTO,
  FOOD_PRODUTO_COMPLEMENTOS,
  FOOD_PRODUTO_COMPLEMENTOS_ITEM
} from "../../../../../../core/endpoints";
import {replaceEndpointParams} from "../../../../../../helpers/endpoints-helpers";

@Component({
  selector: 'ficha-produto',
  templateUrl: './ficha-produto.component.html',
  styleUrls: ['./ficha-produto.component.scss']
})
export class FichaProdutoComponent implements OnInit {

  @Input('complementos') complementos: FoodProdutoComplementos[] = []

  constructor(
    private session: SessionService,
    private service: HttpService
  ) { }

  ngOnInit(): void {
  }

  addGrupo() {
    this.complementos.push(new FoodProdutoComplementos(
      undefined, '', false,'','',[], true
    ))
  }

  addProdutoItem(index: number) {
    if (!this.complementos[index]) return;

    this.complementos[index].items.push(new FoodProdutoComplementosItemsEntity(
      undefined,'','', '', '',''
      , {id: this.session.user.estabalecimento}
    ))
  }

  removeComplemento(complemento: FoodProdutoComplementos, index: number) {
    if (complemento.id) {
      this.service.delete(FOOD_PRODUTO_COMPLEMENTOS+'/'+complemento.id).subscribe(resp => {
        this.complementos.splice(index, 1);
      })
    } else {
      this.complementos.splice(index, 1);
    }
  }

  removeItem(complemento: FoodProdutoComplementos, item: FoodProdutoComplementosItemsEntity, indexItem: number) {
    if (item.id) {
      this.service.delete(FOOD_PRODUTO_COMPLEMENTOS_ITEM+'/'+item.id).subscribe(resp => {
        complemento.items.splice(indexItem, 1);
      })
    } else {
      complemento.items.splice(indexItem, 1);
    }
  }
}
