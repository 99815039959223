import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor, HttpClient
} from '@angular/common/http';
import { Observable } from 'rxjs';
import {AbstractAddToken} from "@datagrupo/dg-ng";
import {TokenService} from "../../../services/token/token.service";

@Injectable()
export class AddTokenInterceptor extends AbstractAddToken implements HttpInterceptor {

  constructor(private token: TokenService) {
    super(token)
  }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return this.rootIntercept(request, next);
  }

  pipeRequestRefreshToken(resp: any): string {
    return "";
  }
}
