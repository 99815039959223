<dg-modal #modal size="md" title="Entregas em andamento" (afterClose)="clear()">
  <div modal-body>
    <div class="row" *ngIf="status == 'FINALIZANDO'">
      <div class="col-12">
        <h4 class="card-title">Houve algum impedimento na entrega?</h4>
        <span class="card-subtitle">Marque as entregas que retornaram</span>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <ul class="list-group">
          <li *ngFor="let venda of vendas" class="list-group-item">
            <div class="card mb-3">
              <div class="row g-0">
                <div *ngIf="status == 'FINALIZANDO'" class="col-md-1 d-flex justify-content-center align-items-center">
                  <input hidden #inputSelectImpeditivo type="checkbox" [(ngModel)]="venda.entregaRetornada">
                  <input type="checkbox" [checked]="inputSelectImpeditivo.checked" (click)="inputSelectImpeditivo.click()">
                </div>
                <div class="{{status == 'FINALIZANDO' ? 'col-md-11' : 'col-md-12'}}">
                  <div class="card-body">
                    <h5 class="card-title">{{venda?.cliente?.nome}}</h5>
                    <p class="card-text">Endereço: Rua cuba 442, penha | Complemento: campainha branca</p>
                  </div>
                </div>
              </div>
              <div *ngIf="venda.entregaRetornada" class="card-footer">
                <span class="card-text">Informe o status que esse pedido deve receber</span>
                <label class="form-label" [(ngModel)]="venda.status">Status do pedido</label>
                <select class="form-select">
                  <option value="EM_ANALISE">EM_ANALISE</option>
                  <option value="PRODUCAO">PRODUCAO</option>
                  <option value="PRONTO">PRONTO</option>
                  <option value="ENTREGUE">ENTREGUE</option>
                </select>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
  <div modal-footer>
    <button *ngIf="status == 'SHOW'" (click)="iniciarFinalizacao()" class="btn btn-success m-1">Finalizar entregas</button>
    <button *ngIf="status == 'FINALIZANDO'" (click)="status = 'SHOW'" class="btn btn-secondary m-1">Voltar</button>
    <button (click)="close()" class="btn btn-secondary m-1">Sair</button>
    <button *ngIf="status == 'FINALIZANDO'" (click)="finalizar()" class="btn btn-success m-1">Finalizar</button>
  </div>
</dg-modal>
