import {Component, OnInit, ViewChild} from '@angular/core';
import {DgModalComponent} from "@datagrupo/dg-ng-util";
import {VendaEntregaEntity} from "../../../../../../entitys/venda-entrega.entity";
import {VendaEntity} from "../../../venda.entity";
import {HttpService} from "../../../../../../services/http-service/http.service";
import {ESTEIRA_PEDIDOS_ENTREGA_FINALIZAR} from "../../../../../../core/endpoints";

@Component({
  selector: 'modal-esteira-entrega-andamento',
  templateUrl: './modal-esteira-entrega-andamento.component.html',
  styleUrls: ['./modal-esteira-entrega-andamento.component.scss']
})
export class ModalEsteiraEntregaAndamentoComponent implements OnInit {

  public data: VendaEntregaEntity | undefined;
  status: 'FINALIZANDO' | 'SHOW' = "SHOW"
  vendas: (VendaEntity & { entregaRetornada: boolean })[] = []

  @ViewChild('modal') modal!: DgModalComponent;

  constructor(private http: HttpService) { }

  ngOnInit(): void {
  }

  open(data: VendaEntregaEntity) {
    if (!data) return;
    this.data = data;

    this.vendas = <(VendaEntity & { entregaRetornada: boolean })[]>data.vendas.map((item: any) => {
      item['entregaRetornada'] = false;
      return item;
    });
    this.modal.open()
  }

  close() {
    this.modal.close()
  }

  clear() {
    this.status = 'SHOW'
  }

  iniciarFinalizacao() {
    this.status = "FINALIZANDO"
  }

  finalizar() {
    const data = <VendaEntregaEntity>{
      ...this.data,
      vendas: this.vendas
    }
    this.http.put(ESTEIRA_PEDIDOS_ENTREGA_FINALIZAR, data).subscribe(resp => {
      this.close();
    })
  }
}
