import { Component, OnInit } from '@angular/core';
import {AbstractInsertEdit2, InsertEditConfig2} from "@datagrupo/dg-crud";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {ProdutoEntity} from "../produto.entity";
import {CategoriaEntity} from "../../categoria/categoria.entity";
import {CATEGORIA} from "../../../../core/endpoints";
import {HttpService} from "../../../../services/http-service/http.service";

@Component({
  selector: 'app-protudos-insert-edit',
  templateUrl: './produtos-insert-edit.component.html',
  styleUrls: ['./produtos-insert-edit.component.scss']
})
export class ProdutosInsertEditComponent extends AbstractInsertEdit2<ProdutoEntity> implements OnInit {

  rootEntity = new ProdutoEntity();
  listCategorias: CategoriaEntity[] = [];

  form = new FormGroup({
    nome: new FormControl('', [Validators.required]),
    valor: new FormControl('', [Validators.required]),
    categorias: new FormControl('', [Validators.required]),
  })

  constructor(public config: InsertEditConfig2, private service: HttpService) {
    super(config)
    service.get(CATEGORIA).subscribe(resp => {
      this.listCategorias = resp.data;
    })
  }

  override ngOnInit(): void {
    super.ngOnInit();
  }

  override afterFetchEntity(entityData: any) {
    this.form.patchValue({
      ...entityData,
      categorias: (entityData.categorias || []).map((item: CategoriaEntity) => item.id),
    })
  }

  override beforeSaveEntity(): boolean {
    if (this.form.invalid) {
      this.form.markAllAsTouched();
      return false;
    }

    const form = this.form.value;

    this.entity = {
      ...this.entity,
      ...form,
      categorias: (form.categorias || []).map((id: number | string) => {
        return { id }
      }),
    }

    return true;
  }

}
