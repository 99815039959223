import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormaPagamentoMainComponent } from './forma-pagamento-main/forma-pagamento-main.component';
import {ReactiveFormsModule} from "@angular/forms";
import {NgxMaskModule} from "ngx-mask";



@NgModule({
  declarations: [
    FormaPagamentoMainComponent
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    NgxMaskModule
  ]
})
export class FormaPagamentoModule { }
