import { Component, OnInit } from '@angular/core';
import {AbstractInsertEdit2, InsertEditConfig2} from "@datagrupo/dg-crud";
import {CategoriaEntity} from "../categoria.entity";
import {FormControl, FormGroup, Validators} from "@angular/forms";

@Component({
  selector: 'app-categoria-insert-edit',
  templateUrl: './categoria-insert-edit.component.html',
  styleUrls: ['./categoria-insert-edit.component.scss']
})
export class CategoriaInsertEditComponent extends AbstractInsertEdit2<CategoriaEntity> implements OnInit {

  rootEntity = new CategoriaEntity();

  form = new FormGroup({
    nome: new FormControl('', [Validators.required]),
  })

  constructor(public config: InsertEditConfig2) {
    super(config)
  }

  override afterFetchEntity(entityData: any) {
    this.form.patchValue({...entityData})
  }

  override ngOnInit(): void {
    super.ngOnInit();
  }

  override beforeSaveEntity(): boolean {
    if (this.form.invalid) {
      this.form.markAllAsTouched();
      return false;
    }

    const form = this.form.value;

    this.entity = {
      ...this.entity,
      ...form
    }

    return true;
  }

}
