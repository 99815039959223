<div id="page-signup" class="container mb-2">
  <div class="row">
    <div class="col-12">
      <mat-stepper [linear]="true" #stepper>
        <mat-step [stepControl]="formEmpresa">
<!--        <mat-step>-->
          <ng-template matStepLabel>Sobre a sua marca</ng-template>

          <div class="card">
            <div class="card-header">
              <h4 class="card-title">Empresa</h4>
            </div>
            <div class="card-body">
              <form [formGroup]="formEmpresa">
                <div class="row">
                  <div class="col-4">
                    img
                  </div>
                  <div class="col-8">
                    <div class="row">
                      <div class="col-6">
                        <label class="form-label">Nome</label>
                        <input name="estabelecimento" class="form-control" formControlName="nome">
                      </div>
                      <div class="col-6">
                        <label class="form-label">Link</label>
                        <input name="estabelecimento-link" class="form-control" formControlName="link">
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-12">
                        <label class="form-label">Discreva a sua empresa</label>
                        <textarea class="form-control"></textarea>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-xl-4 col-lg-4 col-md-6 col-sm-12">
                    <label class="form-label">CEP</label>
                    <input name="cep" class="form-control" mask="00.000-000" formControlName="cep">
                  </div>
                  <div class="col-cl-8 col-lg-8 col-md-6 col-sm-12">
                    <label class="form-label">Logradouro</label>
                    <input class="form-control" formControlName="logradouro">
                  </div>
                  <div class="col-2">
                    <label class="form-label">Numero</label>
                    <input name="numero" class="form-control" formControlName="numero">
                  </div>
                  <div class="col-2">
                    <label class="form-label">complemento</label>
                    <input class="form-control" formControlName="complemento">
                  </div>
                  <div class="col-4">
                    <label class="form-label">Bairro</label>
                    <input class="form-control" formControlName="bairro">
                  </div>
                  <div class="col-2">
                    <label class="form-label">Cidade</label>
                    <input class="form-control" formControlName="cidade">
                  </div>
                  <div class="col-2">
                    <label class="form-label">Estado</label>
                    <input class="form-control" formControlName="estado">
                  </div>
                </div>
              </form>
            </div>
          </div>
        </mat-step>

        <mat-step [completed]="formUser.valid && formasPagamento.length > 0">
<!--        <mat-step>-->
          <ng-template matStepLabel>Configurações iniciai</ng-template>

          <mat-stepper [linear]="true" #stepperConfig>
            <mat-step>
              <ng-template matStepLabel>Usuário master</ng-template>

              <div class="card">
                <div class="card-header">
                  <h4 class="card-title">Usuário</h4>
                </div>
                <div class="card-body">
                  <form [formGroup]="formUser">
                    <div class="row">
                      <div class="col-3">
                        <label class="form-label">Nome</label>
                        <input name="name" class="form-control" formControlName="nome">
                      </div>
                      <div class="col-3">
                        <label class="form-label">E-mail</label>
                        <input name="email" class="form-control" formControlName="email">
                      </div>
                      <div class="col-3">
                        <label class="form-label">Senha</label>
                        <input name="email" class="form-control" formControlName="senha">
                      </div>
                      <div class="col-3">
                        <label class="form-label">Confirmar senha</label>
                        <input name="email" class="form-control" formControlName="confirmSenha">
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </mat-step>

            <mat-step>
              <ng-template matStepLabel>Formas de pagamento</ng-template>

              <div class="card">
                <div class="card-header">
                  <h4 class="card-title">Forma de pagamento</h4>
                  <p class="card-subtitle">Selecione as formas de pagamento</p>
                </div>
                <div class="card-body">

                  <div class="card mb-3" style="height: 80px;">
                    <div class="row g-0">
                      <div class="col-xl-1 col-lg-1 col-md-1 col-sm-1 d-flex justify-content-center align-items-center">
                        <input [checked]="isSelected('DINHEIRO')" (click)="clickSelect('DINHEIRO')" class="form-check" type="checkbox">
                      </div>
                      <div class="col-xl-2 col-lg-2 col-md-2 col-sm-2">
                        <img style="max-height: 70px" src="/assets/images/formas-pagamento/dinheiro.png" class="img-fluid rounded-start" alt="Pix">
                      </div>
                      <div class="col-xl-8 col-lg-8 col-md-8 col-sm-8">
                        <div class="card-body">
                          <h5 class="card-title">Dinheiro</h5>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="card mb-3" style="height: 80px;">
                    <div class="row g-0">
                      <div class="col-xl-1 col-lg-1 col-md-1 col-sm-1 d-flex justify-content-center align-items-center">
                        <input [checked]="isSelected('PIX')" (click)="clickSelect('PIX')" class="form-check" type="checkbox">
                      </div>
                      <div class="col-xl-2 col-lg-2 col-md-2 col-sm-2">
                        <img style="max-height: 70px" src="/assets/images/formas-pagamento/pix.png" class="img-fluid rounded-start" alt="Pix">
                      </div>
                      <div class="col-xl-8 col-lg-8 col-md-8 col-sm-8">
                        <div class="card-body">
                          <h5 class="card-title">Pix</h5>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="card mb-3" style="height: 80px;">
                    <div class="row g-0">
                      <div class="col-xl-1 col-lg-1 col-md-1 col-sm-1 d-flex justify-content-center align-items-center">
                        <input [checked]="isSelected('CARTAO')" (click)="clickSelect('CARTAO')" class="form-check" type="checkbox">
                      </div>
                      <div class="col-xl-2 col-lg-2 col-md-2 col-sm-2">
                        <img style="max-height: 70px" src="/assets/images/formas-pagamento/cartoes.png" class="img-fluid rounded-start" alt="Pix">
                      </div>
                      <div class="col-xl-8 col-lg-8 col-md-8 col-sm-8">
                        <div class="card-body">
                          <h5 class="card-title">Debido/Crédito</h5>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </mat-step>

            <mat-step>
              <ng-template matStepLabel>Horários de funcionamento</ng-template>

              <div class="card">
                <div class="card-header">
                  <h4 class="card-title">Dias de funcionamento na semana</h4>
                </div>
                <div class="card-body">
                  <form [formGroup]="formHorariosFuncionamento">
                    <div class="selecao-dias-trabalhados-semana">
                      <div class="card" [class.disabled]="!(!!formHorariosFuncionamento.value?.domingoStatus)">
                        <div class="card-header">
                          <div class="form-check">
                            <input name="diaFuncionamento" formControlName="domingoStatus" class="form-check-input" type="checkbox" value="" id="dia-domingo">
                            <label class="form-check-label" for="dia-domingo">Ddomingo</label>
                          </div>
                        </div>
                        <div class="card-body">
                          <div>
                            <label class="form-label" style="font-size: 9pt">Abertura</label>
                            <input name="diaFuncionamento" formControlName="domingoAbertura" class="form-control" mask="00:00" placeholder="08:45">
                          </div>
                          <div>
                            <label class="form-label" style="font-size: 9pt">Fechamento</label>
                            <input name="diaFuncionamento" formControlName="domingoFechamento" class="form-control" mask="00:00" placeholder="17:45">
                          </div>
                        </div>
                      </div>

                      <div class="card" [class.disabled]="!(!!formHorariosFuncionamento.value?.segundaStatus)">
                        <div class="card-header">
                          <div class="form-check">
                            <input name="diaFuncionamento"  formControlName="segundaStatus" class="form-check-input" type="checkbox" value="" id="dia-segunda">
                            <label class="form-check-label" for="dia-segunda">Segunda</label>
                          </div>
                        </div>
                        <div class="card-body">
                          <div>
                            <label class="form-label" style="font-size: 9pt">Abertura</label>
                            <input name="diaFuncionamento" formControlName="segundaAbertura" class="form-control" mask="00:00" placeholder="08:45">
                          </div>
                          <div>
                            <label class="form-label" style="font-size: 9pt">Fechamento</label>
                            <input  formControlName="segundaFechamento" class="form-control" mask="00:00" placeholder="17:45">
                          </div>
                        </div>
                      </div>

                      <div class="card" [class.disabled]="!(!!formHorariosFuncionamento.value?.tercaStatus)">
                        <div class="card-header">
                          <div class="form-check">
                            <input name="diaFuncionamento" formControlName="tercaStatus" class="form-check-input" type="checkbox" value="" id="dia-terça">
                            <label class="form-check-label" for="dia-terça">Terça</label>
                          </div>
                        </div>
                        <div class="card-body">
                          <div>
                            <label class="form-label" style="font-size: 9pt">Abertura</label>
                            <input name="diaFuncionamento" formControlName="tercaAbertura" class="form-control" mask="00:00" placeholder="08:45">
                          </div>
                          <div>
                            <label class="form-label" style="font-size: 9pt">Fechamento</label>
                            <input name="diaFuncionamento" formControlName="tercaFechamento" class="form-control" mask="00:00" placeholder="17:45">
                          </div>
                        </div>
                      </div>

                      <div class="card" [class.disabled]="!(!!formHorariosFuncionamento.value?.quartaStatus)">
                        <div class="card-header">
                          <div class="form-check">
                            <input name="diaFuncionamento" formControlName="quartaStatus" class="form-check-input" type="checkbox" value="" id="dia-quarta">
                            <label class="form-check-label" for="dia-quarta">Quarta</label>
                          </div>
                        </div>
                        <div class="card-body">
                          <div>
                            <label class="form-label" style="font-size: 9pt">Abertura</label>
                            <input name="diaFuncionamento" formControlName="quartaAbertura" class="form-control" mask="00:00" placeholder="08:45">
                          </div>
                          <div>
                            <label class="form-label" style="font-size: 9pt">Fechamento</label>
                            <input formControlName="quartaFechamento" class="form-control" mask="00:00" placeholder="17:45">
                          </div>
                        </div>
                      </div>

                      <div class="card" [class.disabled]="!(!!formHorariosFuncionamento.value?.quintaStatus)">
                        <div class="card-header">
                          <div class="form-check">
                            <input name="diaFuncionamento" formControlName="quintaStatus" class="form-check-input" type="checkbox" value="" id="dia-quinta">
                            <label class="form-check-label" for="dia-quinta">Quinta</label>
                          </div>
                        </div>
                        <div class="card-body">
                          <div>
                            <label class="form-label" style="font-size: 9pt">Abertura</label>
                            <input name="diaFuncionamento" formControlName="quintaAbertura" class="form-control" mask="00:00" placeholder="08:45">
                          </div>
                          <div>
                            <label class="form-label" style="font-size: 9pt">Fechamento</label>
                            <input name="diaFuncionamento" formControlName="quintaFechamento" class="form-control" mask="00:00" placeholder="17:45">
                          </div>
                        </div>
                      </div>

                      <div class="card" [class.disabled]="!(!!formHorariosFuncionamento.value?.sextaStatus)">
                        <div class="card-header">
                          <div class="form-check">
                            <input name="diaFuncionamento" formControlName="sextaStatus" class="form-check-input" type="checkbox" value="" id="dia-sexta">
                            <label class="form-check-label" for="dia-sexta">Sexta</label>
                          </div>
                        </div>
                        <div class="card-body">
                          <div>
                            <label class="form-label" style="font-size: 9pt">Abertura</label>
                            <input name="diaFuncionamento" formControlName="sextaAbertura" class="form-control" mask="00:00" placeholder="08:45">
                          </div>
                          <div>
                            <label class="form-label" style="font-size: 9pt">Fechamento</label>
                            <input name="diaFuncionamento" formControlName="sextaFechamento" class="form-control" mask="00:00" placeholder="17:45">
                          </div>
                        </div>
                      </div>

                      <div class="card" [class.disabled]="!(!!formHorariosFuncionamento.value?.sabadoStatus)">
                        <div class="card-header">
                          <div class="form-check">
                            <input name="diaFuncionamento" formControlName="sabadoStatus" class="form-check-input" type="checkbox" value="" id="dia-sabado">
                            <label class="form-check-label" for="dia-sabado">Sábado</label>
                          </div>
                        </div>
                        <div class="card-body">
                          <div>
                            <label class="form-label" style="font-size: 9pt">Abertura</label>
                            <input name="diaFuncionamento" formControlName="sabadoAbertura" class="form-control" mask="00:00" placeholder="08:45">
                          </div>
                          <div>
                            <label class="form-label" style="font-size: 9pt">Fechamento</label>
                            <input name="diaFuncionamento" formControlName="sabadoFechamento" class="form-control" mask="00:00" placeholder="17:45">
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </mat-step>
          </mat-stepper>
        </mat-step>

        <mat-step>
          <ng-template matStepLabel>layout</ng-template>

          <div class="row">
            <div class="col-4" *ngFor="let item of listLayouts">
              <div class="card card-layout" [class.active]="item.value == formLayout.value">
                <img [src]="item.image" class="card-img-top" alt="...">
                <div class="card-body">
                  <h5 class="card-title">{{item.nome}}</h5>
                  <input
                    #inputLayout
                    type="radio"
                    name="layout"
                    hidden
                    [formControl]="formLayout"
                    [value]="item.value"
                  >
                  <button (click)="inputLayout.click()" class="btn btn-primary">Selecionar</button>
                </div>
              </div>
            </div>
          </div>
        </mat-step>
      </mat-stepper>
    </div>
  </div>
</div>


<div class="page-footer page-footer-signup">
  <div class="container mb-2">
    <div class="row">
      <div class="col-12 d-inline-flex justify-content-center">
        <button (click)="back()" class="btn btn-secondary m-1">Voltar</button>
        <button *ngIf="stepper.selectedIndex != 2" (click)="next()" class="btn btn-success m-1">Avançar</button>
        <button [disabled]="disableNext()" *ngIf="stepper.selectedIndex == 2" (click)="salvar()" class="btn btn-success m-1">Finalizar</button>
      </div>
    </div>
  </div>
</div>

<div class="container mt-2">
<!--    <div class="row">-->
<!--      <div class="col-12">-->
<!--        <div class="card">-->
<!--          <div class="card-header">-->
<!--            <h4 class="card-title">Estabelecimento</h4>-->
<!--          </div>-->

<!--          <div class="card-body">-->
<!--            <form [formGroup]="form">-->
<!--              <div class="row">-->
<!--                <div class="col-4">foto</div>-->
<!--                <div class="col-8">-->
<!--                  <div class="row">-->
<!--                    <div class="col-6">-->
<!--                      <label class="form-label">Nome</label>-->
<!--                      <input name="nomeEstabelecimento" class="form-control" formControlName="nome">-->
<!--                    </div>-->
<!--                    <div class="col-6">-->
<!--                      <label class="form-label">Telefone</label>-->
<!--                      <input class="form-control" formControlName="telefone">-->
<!--                    </div>-->
<!--                    <div class="col-6">-->
<!--                      <label class="form-label">Instagram</label>-->
<!--                      <input name="instagran" class="form-control" formControlName="instagram">-->
<!--                    </div>-->
<!--                    <div class="col-6">-->
<!--                      <label class="form-label">Facebook</label>-->
<!--                      <input name="facebook" class="form-control" formControlName="facebook">-->
<!--                    </div>-->
<!--                  </div>-->
<!--                </div>-->
<!--              </div>-->
<!--              <div class="row">-->
<!--                <hr class="mt-3 mb-3">-->
<!--                <h6>Endereço</h6>-->
<!--              </div>-->
<!--              <div class="row">-->
<!--                <div class="col-xl-4 col-lg-4 col-md-6 col-sm-12">-->
<!--                  <label class="form-label">CEP</label>-->
<!--                  <input name="cep" class="form-control" mask="00.000-000" formControlName="cep">-->
<!--                </div>-->
<!--                <div class="col-cl-8 col-lg-8 col-md-6 col-sm-12">-->
<!--                  <label class="form-label">Logradouro</label>-->
<!--                  <input class="form-control" formControlName="logradouro">-->
<!--                </div>-->
<!--                <div class="col-2">-->
<!--                  <label class="form-label">Numero</label>-->
<!--                  <input name="numero" class="form-control" formControlName="numero">-->
<!--                </div>-->
<!--                <div class="col-2">-->
<!--                  <label class="form-label">complemento</label>-->
<!--                  <input class="form-control" formControlName="complemento">-->
<!--                </div>-->
<!--                <div class="col-4">-->
<!--                  <label class="form-label">Bairro</label>-->
<!--                  <input class="form-control" formControlName="bairro">-->
<!--                </div>-->
<!--                <div class="col-2">-->
<!--                  <label class="form-label">Cidade</label>-->
<!--                  <input class="form-control" formControlName="cidade">-->
<!--                </div>-->
<!--                <div class="col-2">-->
<!--                  <label class="form-label">Estado</label>-->
<!--                  <input class="form-control" formControlName="estado">-->
<!--                </div>-->
<!--              </div>-->

<!--              <div class="row mt-3">-->
<!--                <hr>-->
<!--                <h6>Dias de funcionamento na semana</h6>-->
<!--              </div>-->
<!--              <div class="selecao-dias-trabalhados-semana">-->
<!--                <div class="card" [class.disabled]="!(!!form.value?.domingoStatus)">-->
<!--                  <div class="card-header">-->
<!--                    <div class="form-check">-->
<!--                      <input name="diaFuncionamento" formControlName="domingoStatus" class="form-check-input" type="checkbox" value="" id="dia-domingo">-->
<!--                      <label class="form-check-label" for="dia-domingo">Ddomingo</label>-->
<!--                    </div>-->
<!--                  </div>-->
<!--                  <div class="card-body">-->
<!--                    <div>-->
<!--                      <label class="form-label" style="font-size: 9pt">Abertura</label>-->
<!--                      <input name="diaFuncionamento" formControlName="domingoAbertura" class="form-control" mask="00:00" placeholder="08:45">-->
<!--                    </div>-->
<!--                    <div>-->
<!--                      <label class="form-label" style="font-size: 9pt">Fechamento</label>-->
<!--                      <input name="diaFuncionamento" formControlName="domingoFechamento" class="form-control" mask="00:00" placeholder="17:45">-->
<!--                    </div>-->
<!--                  </div>-->
<!--                </div>-->

<!--                <div class="card" [class.disabled]="!(!!form.value?.segundaStatus)">-->
<!--                  <div class="card-header">-->
<!--                    <div class="form-check">-->
<!--                      <input name="diaFuncionamento"  formControlName="segundaStatus" class="form-check-input" type="checkbox" value="" id="dia-segunda">-->
<!--                      <label class="form-check-label" for="dia-segunda">Segunda</label>-->
<!--                    </div>-->
<!--                  </div>-->
<!--                  <div class="card-body">-->
<!--                    <div>-->
<!--                      <label class="form-label" style="font-size: 9pt">Abertura</label>-->
<!--                      <input name="diaFuncionamento" formControlName="segundaAbertura" class="form-control" mask="00:00" placeholder="08:45">-->
<!--                    </div>-->
<!--                    <div>-->
<!--                      <label class="form-label" style="font-size: 9pt">Fechamento</label>-->
<!--                      <input  formControlName="segundaFechamento" class="form-control" mask="00:00" placeholder="17:45">-->
<!--                    </div>-->
<!--                  </div>-->
<!--                </div>-->

<!--                <div class="card" [class.disabled]="!(!!form.value?.tercaStatus)">-->
<!--                  <div class="card-header">-->
<!--                    <div class="form-check">-->
<!--                      <input name="diaFuncionamento" formControlName="tercaStatus" class="form-check-input" type="checkbox" value="" id="dia-terça">-->
<!--                      <label class="form-check-label" for="dia-terça">Terça</label>-->
<!--                    </div>-->
<!--                  </div>-->
<!--                  <div class="card-body">-->
<!--                    <div>-->
<!--                      <label class="form-label" style="font-size: 9pt">Abertura</label>-->
<!--                      <input name="diaFuncionamento" formControlName="tercaAbertura" class="form-control" mask="00:00" placeholder="08:45">-->
<!--                    </div>-->
<!--                    <div>-->
<!--                      <label class="form-label" style="font-size: 9pt">Fechamento</label>-->
<!--                      <input name="diaFuncionamento" formControlName="tercaFechamento" class="form-control" mask="00:00" placeholder="17:45">-->
<!--                    </div>-->
<!--                  </div>-->
<!--                </div>-->

<!--                <div class="card" [class.disabled]="!(!!form.value?.quartaStatus)">-->
<!--                  <div class="card-header">-->
<!--                    <div class="form-check">-->
<!--                      <input name="diaFuncionamento" formControlName="quartaStatus" class="form-check-input" type="checkbox" value="" id="dia-quarta">-->
<!--                      <label class="form-check-label" for="dia-quarta">Quarta</label>-->
<!--                    </div>-->
<!--                  </div>-->
<!--                  <div class="card-body">-->
<!--                    <div>-->
<!--                      <label class="form-label" style="font-size: 9pt">Abertura</label>-->
<!--                      <input name="diaFuncionamento" formControlName="quartaAbertura" class="form-control" mask="00:00" placeholder="08:45">-->
<!--                    </div>-->
<!--                    <div>-->
<!--                      <label class="form-label" style="font-size: 9pt">Fechamento</label>-->
<!--                      <input formControlName="quartaFechamento" class="form-control" mask="00:00" placeholder="17:45">-->
<!--                    </div>-->
<!--                  </div>-->
<!--                </div>-->

<!--                <div class="card" [class.disabled]="!(!!form.value?.quintaStatus)">-->
<!--                  <div class="card-header">-->
<!--                    <div class="form-check">-->
<!--                      <input name="diaFuncionamento" formControlName="quintaStatus" class="form-check-input" type="checkbox" value="" id="dia-quinta">-->
<!--                      <label class="form-check-label" for="dia-quinta">Quinta</label>-->
<!--                    </div>-->
<!--                  </div>-->
<!--                  <div class="card-body">-->
<!--                    <div>-->
<!--                      <label class="form-label" style="font-size: 9pt">Abertura</label>-->
<!--                      <input name="diaFuncionamento" formControlName="quintaAbertura" class="form-control" mask="00:00" placeholder="08:45">-->
<!--                    </div>-->
<!--                    <div>-->
<!--                      <label class="form-label" style="font-size: 9pt">Fechamento</label>-->
<!--                      <input name="diaFuncionamento" formControlName="quintaFechamento" class="form-control" mask="00:00" placeholder="17:45">-->
<!--                    </div>-->
<!--                  </div>-->
<!--                </div>-->

<!--                <div class="card" [class.disabled]="!(!!form.value?.sextaStatus)">-->
<!--                  <div class="card-header">-->
<!--                    <div class="form-check">-->
<!--                      <input name="diaFuncionamento" formControlName="sextaStatus" class="form-check-input" type="checkbox" value="" id="dia-sexta">-->
<!--                      <label class="form-check-label" for="dia-sexta">Sexta</label>-->
<!--                    </div>-->
<!--                  </div>-->
<!--                  <div class="card-body">-->
<!--                    <div>-->
<!--                      <label class="form-label" style="font-size: 9pt">Abertura</label>-->
<!--                      <input name="diaFuncionamento" formControlName="sextaAbertura" class="form-control" mask="00:00" placeholder="08:45">-->
<!--                    </div>-->
<!--                    <div>-->
<!--                      <label class="form-label" style="font-size: 9pt">Fechamento</label>-->
<!--                      <input name="diaFuncionamento" formControlName="sextaFechamento" class="form-control" mask="00:00" placeholder="17:45">-->
<!--                    </div>-->
<!--                  </div>-->
<!--                </div>-->

<!--                <div class="card" [class.disabled]="!(!!form.value?.sabadoStatus)">-->
<!--                  <div class="card-header">-->
<!--                    <div class="form-check">-->
<!--                      <input name="diaFuncionamento" formControlName="sabadoStatus" class="form-check-input" type="checkbox" value="" id="dia-sabado">-->
<!--                      <label class="form-check-label" for="dia-sabado">Sábado</label>-->
<!--                    </div>-->
<!--                  </div>-->
<!--                  <div class="card-body">-->
<!--                    <div>-->
<!--                      <label class="form-label" style="font-size: 9pt">Abertura</label>-->
<!--                      <input name="diaFuncionamento" formControlName="sabadoAbertura" class="form-control" mask="00:00" placeholder="08:45">-->
<!--                    </div>-->
<!--                    <div>-->
<!--                      <label class="form-label" style="font-size: 9pt">Fechamento</label>-->
<!--                      <input name="diaFuncionamento" formControlName="sabadoFechamento" class="form-control" mask="00:00" placeholder="17:45">-->
<!--                    </div>-->
<!--                  </div>-->
<!--                </div>-->
<!--              </div>-->

<!--            </form>-->
<!--          </div>-->
<!--          <div class="card-footer">-->
<!--            <button (click)="salvar()" class="btn btn-success">Salvar</button>-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->
<!--    </div>-->
</div>
