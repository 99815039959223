import {Component, EventEmitter, OnInit, Output, ViewChild} from '@angular/core';
import {DgModalComponent} from "@datagrupo/dg-ng-util";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {HttpService} from "../../../../services/http-service/http.service";
import {ENTREGADOR} from "../../../../core/endpoints";
import {Observable, Subscription} from "rxjs";
import Swal from "sweetalert2";
import {EntregadorEntity} from "../entregador.entity";

@Component({
  selector: 'modal-entregador-insert-edit',
  templateUrl: './modal-entregador-insert-edit.component.html',
  styleUrls: ['./modal-entregador-insert-edit.component.scss']
})
export class ModalEntregadorInsertEditComponent implements OnInit {

  @ViewChild('modal') modal!: DgModalComponent;

  @Output('afterSave') afterSave = new EventEmitter<void>()

  form = new FormGroup({
    id: new FormControl(''),
    nome: new FormControl('', [Validators.required]),
    placa: new FormControl('', [Validators.required]),
  })

  constructor(private http: HttpService) { }

  ngOnInit(): void {
  }

  open(entregador?: EntregadorEntity) {
    if (entregador) {
      this.http.get(ENTREGADOR+'/'+entregador.id).subscribe(resp => {
        this.form.patchValue(resp.data)
        this.modal.open()
      })
    } else {
      this.modal.open()
    }
  }

  close() {
    this.modal.close()
  }

  clear() {
    this.form.reset('')
  }

  save() {
    if (this.form.invalid) {
      this.form.markAllAsTouched();
      return
    }

    const form = this.form.value;
    let request: Observable<any>

    if (form.id) {
      request = this.http.put(ENTREGADOR, form)
    } else {
      delete form.id;
      request = this.http.post(ENTREGADOR, form)
    }

    request.subscribe(resp => {
      this.afterSave.emit()
      Swal.fire({
        icon: "success",
        title: form.id ? 'Atualizações salvas' : 'Entregador cadastrado'
      }).then()
      this.close()
    })
  }
}
