import { Component, OnInit } from '@angular/core';
import {Router} from "@angular/router";

@Component({
  selector: 'app-start-session',
  templateUrl: './start-session.component.html',
  styleUrls: ['./start-session.component.scss']
})
export class StartSessionComponent implements OnInit {

  constructor(private router: Router) {
    router.navigate(['private', 'home']).then();
  }

  ngOnInit(): void {
  }

}
