<dg-modal
  #modal
  id="modal-esteira-pedido"
  size="lg"
  title="Pedido N° 1235"
  (afterClose)="clear()"
>
  <div modal-body class="container-fluid">
    <div class="row">
      <div class="col-10">
        <div class="row">
          <div class="col-4">
            <label class="form-label">Cliente</label>
            <p class="text-muted">{{entity?.cliente?.nome}}</p>
          </div>
          <div class="col-4">
            <label class="form-label">Whatsapp</label>
            <p class="text-muted">{{entity?.cliente?.telefone}}</p>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <label class="form-label">Pedido</label>
          </div>
          <div class="col-12">
            <ul class="list-group">
              <li class="list-group-item" *ngFor="let produto of (entity?.produtos || [])">
                {{produto?.nome}}

                <ul class="list-group">
                  <li class="list-group-item" *ngFor="let complemento of (produto.complementos || [])">
                    {{complemento?.nome}}

                    <ul class="list-group">
                      <li class="list-group-item" *ngFor="let item of (complemento?.items || [])">
                        {{item?.nome}}
                      </li>
                    </ul>
                  </li>
                </ul>
              </li>
            </ul>
          </div>
        </div>
<!--        <pre>{{entity | json}}</pre>-->
      </div>
      <div class="col-2">
        <button *ngIf="entity?.status == 'EM_ANALISE'" (click)="changeStatus('PRODUCAO')" class="btn btn-success">Aceitar pedido</button>
        <button *ngIf="entity?.status == 'PRODUCAO'" (click)="changeStatus('PRONTO')" class="btn btn-success">Pedido pronto</button>
<!--        <button *ngIf="entity?.status == 'PRONTO'" (click)="changeStatus('CAMINHO')" class="btn btn-success">A caminho</button>-->
<!--        <button *ngIf="entity?.status == 'CAMINHO'" (click)="changeStatus('ENTREGUE')" class="btn btn-success">Finalizado</button>-->
      </div>
    </div>
  </div>
  <div modal-footer></div>
</dg-modal>
