import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {CategoriaMainComponent} from "./categoria-main/categoria-main.component";
import { CategoriaInsertEditComponent } from './categoria-insert-edit/categoria-insert-edit.component';
import {CdkDynamicTableModule, DgPaginatorModule, DgTableModule} from "@datagrupo/dg-ng-util";
import {ReactiveFormsModule} from "@angular/forms";
import {DgCrudModule} from "@datagrupo/dg-crud";
import {RouterModule} from "@angular/router";



@NgModule({
  declarations: [
    CategoriaMainComponent,
    CategoriaInsertEditComponent
  ],
    imports: [
        CommonModule,
        DgTableModule,
        CdkDynamicTableModule,
        DgPaginatorModule,
        ReactiveFormsModule,
        DgCrudModule,
        RouterModule
    ]
})
export class CategoriaModule { }
