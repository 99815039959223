import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {statusPedidoType} from "../../../../../../enums/status-pedido.enum";
import {VendaEntity} from "../../../venda.entity";
import {HttpService} from "../../../../../../services/http-service/http.service";
import {FoodEsteiraPedidoService} from "../../service/food-esteira-pedido.service";

@Component({
  selector: 'card-pedido-esteira',
  templateUrl: './card-pedido-esteira.component.html',
  styleUrls: ['./card-pedido-esteira.component.scss']
})
export class CardPedidoEsteiraComponent implements OnInit {

  @Input('data') data: any;
  @Output() clickSelect = new EventEmitter()


  constructor(private http: HttpService, public esteiraService: FoodEsteiraPedidoService) { }

  ngOnInit(): void {
  }

  changeStatus(venda: VendaEntity, status: statusPedidoType) {
    if (!venda?.id || !venda.status) return;
    this.esteiraService.changeStatusArray([venda.id], venda.status, status)
  }

  _clickSelect(ev: MouseEvent) {
    this.clickSelect.emit(ev)
  }
}












