import { Component, OnInit } from '@angular/core';
import {AbstractInsertEdit2, InsertEditConfig2} from "@datagrupo/dg-crud";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {FoodCategoriaEntity} from "../food-categoria.entity";

@Component({
  selector: 'app-food-categorias-insert-edit',
  templateUrl: './food-categorias-insert-edit.component.html',
  styleUrls: ['./food-categorias-insert-edit.component.scss']
})
export class FoodCategoriasInsertEditComponent extends AbstractInsertEdit2<FoodCategoriaEntity> implements OnInit {

  rootEntity = new FoodCategoriaEntity();

  form = new FormGroup({
    nome: new FormControl('', [Validators.required]),
  })

  constructor(public config: InsertEditConfig2) {
    super(config)
  }

  override afterFetchEntity(entityData: any) {
    this.form.patchValue({...entityData})
  }

  override ngOnInit(): void {
    super.ngOnInit();
  }

  override beforeSaveEntity(): boolean {
    if (this.form.invalid) {
      this.form.markAllAsTouched();
      return false;
    }

    const form = this.form.value;

    this.entity = {
      ...this.entity,
      ...form
    }

    return true;
  }

}
