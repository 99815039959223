import {AbstractEntity2, CrudEntity} from "@datagrupo/dg-crud";
import {environment} from "../../../../environments/environment";
import {SERVICOS_AGENDAVEIS} from "../../../core/endpoints";
import {DynamicTableEntity, DynamicColumn} from "@datagrupo/dg-ng-util";


@CrudEntity({
  api: {
    path: environment.apiUrl,
    context: SERVICOS_AGENDAVEIS
  }
})
@DynamicTableEntity({
  api: {
    path: environment.apiUrl,
    context: SERVICOS_AGENDAVEIS
  }
})
export class ServicosAgendaveisEntity extends AbstractEntity2 {

  constructor(
    nome?: string,
    tempo?: string,
    colaboradores?: string,
    valor?: string,
    comissao?: string,
    categorias?: string
  ) {
    super();
    this.nome = nome;
    this.tempo = tempo;
    this.colaboradores = colaboradores;
    this.valor = valor;
    this.comissao = comissao;
    this.categorias = categorias;
  }

  @DynamicColumn({ headerName: 'Nome' })
  nome: string | undefined;
  @DynamicColumn({ headerName: 'tempo' })
  tempo: string | undefined;
  @DynamicColumn({ headerName: 'Colaboradores' })
  colaboradores: string | undefined;
  @DynamicColumn({ headerName: 'valor' })
  valor: string | undefined;
  @DynamicColumn({ headerName: 'comissao' })
  comissao: string | undefined;
  @DynamicColumn({ headerName: 'categorias' })
  categorias: string | undefined;
}
