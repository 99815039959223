import { Component, OnInit } from '@angular/core';
import {CdkDynamicTable, CdkDynamicTableService} from "@datagrupo/dg-ng-util";
import {environment} from "../../../../../environments/environment";
import {AGENDAMENTO} from "../../../../core/endpoints";

@Component({
  selector: 'app-acompanhamento',
  templateUrl: './acompanhamento.component.html',
  styleUrls: ['./acompanhamento.component.scss']
})
export class AcompanhamentoComponent implements OnInit {

  table: CdkDynamicTable.tableClass;

  constructor(private cdkTable: CdkDynamicTableService) {
    this.table = cdkTable.create('request', {
      columns: ['date'],
      apiData: {
        path: environment.apiUrl,
        context: AGENDAMENTO
      }
    });
  }

  ngOnInit(): void {
  }

}
