<nav id="user-header-principal" class="navbar navbar-expand-md">
  <div class="container-fluid">

    <button style="border: none; background: none" class="mr-auto" (click)="sidebarChange()">
      <mat-icon [hidden]="sidebaOpened()">menu</mat-icon>
      <mat-icon [hidden]="!sidebaOpened()">menu_open</mat-icon>
    </button>

    <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#header-user-minha-coleta" aria-controls="header-user-minha-coleta" aria-expanded="false" aria-label="Toggle navigation">
      <mat-icon>menu</mat-icon>
    </button>

    <div class="collapse navbar-collapse h-100" id="header-user-minha-coleta">
      <ul class="navbar-nav ms-auto mb-2 mb-lg-0 h-100">
        <li class="nav-item">
          <mat-icon class="icon-center">receipt_long</mat-icon>
          <span *ngIf="!!numeroPedidosEmAnalise" class="badge bg-primary rounded-pill">
            {{numeroPedidosEmAnalise}}
          </span>
        </li>
        <li class="nav-item dropdown">
          <span
            class="nav-link dropdown-toggle"
            href="#"
            id="navbarDropdown"
            role="button"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <mat-icon class="icon-center">account_circle</mat-icon>
          </span>
          <ul class="dropdown-menu dropdown-menu-end" aria-labelledby="navbarDropdown">
            <li><a class="dropdown-item" href="#">Action</a></li>
            <li><a class="dropdown-item" href="#">Another action</a></li>
            <li><hr class="dropdown-divider"></li>
            <li>
              <mat-icon class="icon-center">logout</mat-icon>
              <span class="dropdown-item" (click)="session.logout()">
              Sair
            </span>
            </li>
          </ul>
        </li>
<!--        <li class="nav-item user-item" (click)="session.logout()">-->
<!--          <mat-icon class="icon-center">logout</mat-icon>-->
<!--          <span class="ff-gotham-light user logout" routerLink="/login" (click)="session.logout()">SAIR</span>-->
<!--        </li>-->
      </ul>
    </div>

  </div>
</nav>
