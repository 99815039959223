import { Injectable } from '@angular/core';
import {AbstractTokenService} from "@datagrupo/dg-ng";
import {environment} from "../../../environments/environment";
import {HttpClient} from "@angular/common/http";
import {tipoPerfil} from "../session/session.service";

interface token {
  sub: number | string,
  perfil: tipoPerfil
  subEstab: number | string,
}

@Injectable({
  providedIn: 'root'
})
export class TokenService extends AbstractTokenService<token> {

  path_refreshToken = environment.apiUrl + 'auth/refresh';

  constructor(public http: HttpClient) {
    super(http);
  }
}
