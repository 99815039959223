import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'card-entrega-pedido-esteira',
  templateUrl: './card-entrega-pedido-esteira.component.html',
  styleUrls: ['./card-entrega-pedido-esteira.component.scss']
})
export class CardEntregaPedidoEsteiraComponent implements OnInit {

  @Input('data') data: any;

  @Output('open') open = new EventEmitter()

  constructor() { }

  ngOnInit(): void {
  }

}
