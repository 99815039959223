import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EstabelecimentoMainComponent } from './estabelecimento-main/estabelecimento-main.component';
import { EstabelecimentoInsertEditComponent } from './estabelecimento-insert-edit/estabelecimento-insert-edit.component';
import {CdkDynamicTableModule, DgPaginatorModule, DgTableModule} from "@datagrupo/dg-ng-util";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {DgCrudModule} from "@datagrupo/dg-crud";
import {RouterModule} from "@angular/router";
import {NgxMaskModule} from "ngx-mask";



@NgModule({
  declarations: [
    EstabelecimentoMainComponent,
    EstabelecimentoInsertEditComponent
  ],
    imports: [
        CommonModule,
        DgTableModule,
        CdkDynamicTableModule,
        DgPaginatorModule,
        ReactiveFormsModule,
        DgCrudModule,
        RouterModule,
        NgxMaskModule,
        FormsModule
    ]
})
export class EstabelecimentoModule { }
