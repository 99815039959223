import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {DgSidebarMenuComponent} from "@datagrupo/dg-ng-util";
import {SessionService} from "../../../../services/session/session.service";
import {WebsocketService} from "../../../../services/websocket/websocket.service";
import {HttpService} from "../../../../services/http-service/http.service";
import {ESTEIRA_PEDIDOS_ESTEIRA_COUNT} from "../../../../core/endpoints";
import {statusPedidoType} from "../../../../enums/status-pedido.enum";

@Component({
  selector: 'private-header',
  templateUrl: './private-header.component.html',
  styleUrls: ['./private-header.component.scss']
})
export class PrivateHeaderComponent implements OnInit {

  @Output() changeSidebar = new EventEmitter<any>()

  numeroPedidosEmAnalise: number = 0;
  private notificationSound = new Audio('assets/sounds/notification-1.wav');

  constructor(
    public session: SessionService,
    private socketService: WebsocketService,
    private http: HttpService
  ) {
    socketService.socket.on(
      'esteira-produtos-'+session.user.estabalecimento,
      (resp: {status: statusPedidoType[], novoProduto: boolean}) => {

      if (resp.status.includes('EM_ANALISE')) {
        this.findNovosPedidos(resp.novoProduto)
      }
    })
  }

  ngOnInit(): void {
  }

  sidebarChange() {
    this.changeSidebar.emit();
  }

  sidebaOpened() {
    return DgSidebarMenuComponent.open
  }

  findNovosPedidos(novoPedido?: boolean) {
    this.http.get(ESTEIRA_PEDIDOS_ESTEIRA_COUNT, { params: { status: 'EM_ANALISE' } }).subscribe(resp => {
      this.numeroPedidosEmAnalise = resp.data;
      if (!!novoPedido) {
        this.notificationSound?.play().then()
      }
    })
  }
}
