import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AgendaComponent } from './agenda/agenda.component';
import { AcompanhamentoComponent } from './acompanhamento/acompanhamento.component';
import {CdkDynamicTableModule, DgPaginatorModule, DgTableModule} from "@datagrupo/dg-ng-util";
import {RouterModule} from "@angular/router";



@NgModule({
  declarations: [
    AgendaComponent,
    AcompanhamentoComponent
  ],
  imports: [
    CommonModule,
    DgPaginatorModule,
    DgTableModule,
    CdkDynamicTableModule,
    RouterModule
  ]
})
export class AgendaModule { }
