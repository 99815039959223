import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {FoodProdutosModule} from "./food-produtos/food-produtos.module";
import {FoodCategoriasModule} from "./food-categorias/food-categorias.module";
import {FoodEsteiraPedidosModule} from "./food-esteira-pedidos/food-esteira-pedidos.module";



@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    FoodProdutosModule,
    FoodCategoriasModule,
    FoodEsteiraPedidosModule
  ]
})
export class FoodModule { }
