<div class="container" *ngIf="!!entity">
  <div class="row">
    <div class="col-12">
      <div class="card">
        <div class="card-header">
          <h4 class="card-title">Estabelecimento</h4>
        </div>

        <div class="card-body">
          <form [formGroup]="form">
            <div class="row">
              <div class="col-4">foto</div>
              <div class="col-8">
                <div class="row">
                  <div class="col-6">
                    <label class="form-label">Nome</label>
                    <input class="form-control" formControlName="nome">
                  </div>
                  <div class="col-6">
                    <label class="form-label">Telefone</label>
                    <input class="form-control" formControlName="telefone">
                  </div>
                  <div class="col-6">
                    <label class="form-label">Instagram</label>
                    <input class="form-control" formControlName="instagram">
                  </div>
                  <div class="col-6">
                    <label class="form-label">Facebook</label>
                    <input class="form-control" formControlName="facebook">
                  </div>
                  <div class="col-6">
                    <label class="form-label">Link site</label>
                    <input class="form-control" formControlName="link">
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <hr class="mt-3 mb-3">
              <h6>Endereço</h6>
            </div>
            <div class="row">
              <div class="col-xl-4 col-lg-4 col-md-6 col-sm-12">
                <label class="form-label">CEP</label>
                <input class="form-control" mask="00.000-000" formControlName="cep">
              </div>
              <div class="col-cl-8 col-lg-8 col-md-6 col-sm-12">
                <label class="form-label">Logradouro</label>
                <input class="form-control" formControlName="logradouro">
              </div>
              <div class="col-2">
                <label class="form-label">Numero</label>
                <input class="form-control" formControlName="numero">
              </div>
              <div class="col-2">
                <label class="form-label">complemento</label>
                <input class="form-control" formControlName="complemento">
              </div>
              <div class="col-4">
                <label class="form-label">Bairro</label>
                <input class="form-control" formControlName="bairro">
              </div>
              <div class="col-2">
                <label class="form-label">Cidade</label>
                <input class="form-control" formControlName="cidade">
              </div>
              <div class="col-2">
                <label class="form-label">Estado</label>
                <input class="form-control" formControlName="estado">
              </div>
            </div>

            <div class="row mt-3">
              <hr>
              <h6>Dias de funcionamento na semana</h6>
            </div>
            <div class="selecao-dias-trabalhados-semana">
              <div class="card" [class.disabled]="!(!!form.value?.domingoStatus)">
                <div class="card-header">
                  <div class="form-check">
                    <input formControlName="domingoStatus" class="form-check-input" type="checkbox" value="" id="dia-domingo">
                    <label class="form-check-label" for="dia-domingo">Ddomingo</label>
                  </div>
                </div>
                <div class="card-body">
                  <div>
                    <label class="form-label" style="font-size: 9pt">Abertura</label>
                    <input formControlName="domingoAbertura" class="form-control" mask="00:00" placeholder="08:45">
                  </div>
                  <div>
                    <label class="form-label" style="font-size: 9pt">Fechamento</label>
                    <input formControlName="domingoFechamento" class="form-control" mask="00:00" placeholder="17:45">
                  </div>
                </div>
              </div>

              <div class="card" [class.disabled]="!(!!form.value?.segundaStatus)">
                <div class="card-header">
                  <div class="form-check">
                    <input  formControlName="segundaStatus" class="form-check-input" type="checkbox" value="" id="dia-segunda">
                    <label class="form-check-label" for="dia-segunda">Segunda</label>
                  </div>
                </div>
                <div class="card-body">
                  <div>
                    <label class="form-label" style="font-size: 9pt">Abertura</label>
                    <input  formControlName="segundaAbertura" class="form-control" mask="00:00" placeholder="08:45">
                  </div>
                  <div>
                    <label class="form-label" style="font-size: 9pt">Fechamento</label>
                    <input  formControlName="segundaFechamento" class="form-control" mask="00:00" placeholder="17:45">
                  </div>
                </div>
              </div>

              <div class="card" [class.disabled]="!(!!form.value?.tercaStatus)">
                <div class="card-header">
                  <div class="form-check">
                    <input formControlName="tercaStatus" class="form-check-input" type="checkbox" value="" id="dia-terça">
                    <label class="form-check-label" for="dia-terça">Terça</label>
                  </div>
                </div>
                <div class="card-body">
                  <div>
                    <label class="form-label" style="font-size: 9pt">Abertura</label>
                    <input formControlName="tercaAbertura" class="form-control" mask="00:00" placeholder="08:45">
                  </div>
                  <div>
                    <label class="form-label" style="font-size: 9pt">Fechamento</label>
                    <input formControlName="tercaFechamento" class="form-control" mask="00:00" placeholder="17:45">
                  </div>
                </div>
              </div>

              <div class="card" [class.disabled]="!(!!form.value?.quartaStatus)">
                <div class="card-header">
                  <div class="form-check">
                    <input formControlName="quartaStatus" class="form-check-input" type="checkbox" value="" id="dia-quarta">
                    <label class="form-check-label" for="dia-quarta">Quarta</label>
                  </div>
                </div>
                <div class="card-body">
                  <div>
                    <label class="form-label" style="font-size: 9pt">Abertura</label>
                    <input formControlName="quartaAbertura" class="form-control" mask="00:00" placeholder="08:45">
                  </div>
                  <div>
                    <label class="form-label" style="font-size: 9pt">Fechamento</label>
                    <input formControlName="quartaFechamento" class="form-control" mask="00:00" placeholder="17:45">
                  </div>
                </div>
              </div>

              <div class="card" [class.disabled]="!(!!form.value?.quintaStatus)">
                <div class="card-header">
                  <div class="form-check">
                    <input formControlName="quintaStatus" class="form-check-input" type="checkbox" value="" id="dia-quinta">
                    <label class="form-check-label" for="dia-quinta">Quinta</label>
                  </div>
                </div>
                <div class="card-body">
                  <div>
                    <label class="form-label" style="font-size: 9pt">Abertura</label>
                    <input formControlName="quintaAbertura" class="form-control" mask="00:00" placeholder="08:45">
                  </div>
                  <div>
                    <label class="form-label" style="font-size: 9pt">Fechamento</label>
                    <input formControlName="quintaFechamento" class="form-control" mask="00:00" placeholder="17:45">
                  </div>
                </div>
              </div>

              <div class="card" [class.disabled]="!(!!form.value?.sextaStatus)">
                <div class="card-header">
                  <div class="form-check">
                    <input formControlName="sextaStatus" class="form-check-input" type="checkbox" value="" id="dia-sexta">
                    <label class="form-check-label" for="dia-sexta">Sexta</label>
                  </div>
                </div>
                <div class="card-body">
                  <div>
                    <label class="form-label" style="font-size: 9pt">Abertura</label>
                    <input formControlName="sextaAbertura" class="form-control" mask="00:00" placeholder="08:45">
                  </div>
                  <div>
                    <label class="form-label" style="font-size: 9pt">Fechamento</label>
                    <input formControlName="sextaFechamento" class="form-control" mask="00:00" placeholder="17:45">
                  </div>
                </div>
              </div>

              <div class="card" [class.disabled]="!(!!form.value?.sabadoStatus)">
                <div class="card-header">
                  <div class="form-check">
                    <input  formControlName="sabadoStatus" class="form-check-input" type="checkbox" value="" id="dia-sabado">
                    <label class="form-check-label" for="dia-sabado">Sábado</label>
                  </div>
                </div>
                <div class="card-body">
                  <div>
                    <label class="form-label" style="font-size: 9pt">Abertura</label>
                    <input  formControlName="sabadoAbertura" class="form-control" mask="00:00" placeholder="08:45">
                  </div>
                  <div>
                    <label class="form-label" style="font-size: 9pt">Fechamento</label>
                    <input  formControlName="sabadoFechamento" class="form-control" mask="00:00" placeholder="17:45">
                  </div>
                </div>
              </div>
            </div>

          </form>
        </div>
        <div class="card-footer">
          <button crud-salvar class="btn btn-success">Salvar</button>
        </div>
      </div>
    </div>
  </div>

</div>
