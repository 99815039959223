import {Component, OnInit, ViewChild} from '@angular/core';
import {DgModalComponent} from "@datagrupo/dg-ng-util";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {FoodEsteiraPedidoService} from "../../service/food-esteira-pedido.service";
import {CdkDragDrop, moveItemInArray, transferArrayItem} from "@angular/cdk/drag-drop";
import {VendaEntity} from "../../../venda.entity";
import Swal from "sweetalert2";
import {Observable} from "rxjs";
import {HttpService} from "../../../../../../services/http-service/http.service";
import {ESTEIRA_PEDIDOS_ENTREGA, EXPEDIENTE_ENTREGADOR} from "../../../../../../core/endpoints";
import {VendaEntregaEntity} from "../../../../../../entitys/venda-entrega.entity";
import {EntregadorEntity} from "../../../../entregador/entregador.entity";

@Component({
  selector: 'modal-esteira-entrega',
  templateUrl: './modal-esteira-entrega.component.html',
  styleUrls: ['./modal-esteira-entrega.component.scss']
})
export class ModalEsteiraEntregaComponent implements OnInit {

  @ViewChild('modal') modal!: DgModalComponent;

  public entity: VendaEntregaEntity = new VendaEntregaEntity()

  listPedidosProntos: VendaEntity[] = []
  listEntregadores: EntregadorEntity[] = []

  form = new FormGroup({
    id: new FormControl(''),
    entregador: new FormControl('', [Validators.required])
  })

  constructor(public esteiraService: FoodEsteiraPedidoService, private http: HttpService) { }

  ngOnInit(): void {
  }

  open(data?: VendaEntregaEntity) {
    this.http.get(EXPEDIENTE_ENTREGADOR).subscribe(resp => {
      this.listEntregadores = resp.data;

      this.listPedidosProntos = JSON.parse(JSON.stringify(this.esteiraService.list_status_pedido.Pronto));

      if (!!data) {
        this.entity = data;
        this.form.patchValue({
          id: data.id,
          entregador: data?.entregador?.id || ''
        })
      }

      this.modal.open()
    })
  }

  close() {
    this.modal.close()
  }

  clear() {
    this.form.reset('');
    this.listPedidosProntos = [];
    this.entity = new VendaEntregaEntity();
  }

  drop(event: CdkDragDrop<VendaEntity[]>) {
    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    } else {
      transferArrayItem(
        event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex,
      );
    }
  }

  save() {
    if (this.form.invalid) {
      this.form.markAllAsTouched();
      return;
    }

    if (this.entity.vendas.length <= 0) {
      Swal.fire({
        icon: 'warning',
        title: 'Nenhum pedido selecionado',
        text: 'Selecione ao menos um produto para entrega'
      }).then()
      return;
    }

    Swal.fire({
      icon: 'question',
      title: 'Iniciar viagem?',
      text: 'Clique em "Finalizar depois" para continuar adicionando pedidos a essa viagem',
      showDenyButton: true,
      showCancelButton: true,
      denyButtonText: 'Finalizar depois',
      cancelButtonText: 'Voltar',
      confirmButtonText: 'Iniciar viagem'
    }).then(confirm => {
      if (confirm.isDenied || confirm.isConfirmed) {
        this.entity.status = confirm.isDenied ? 'PREPARO_ENTREGA' : 'ENTREGA_ANDAMENTO';
        this._save();
      }
    })
  }

  private _save() {
    const {id, entregador} = this.form.value;

    this.entity = {
      ...this.entity,
      id,
      entregador: { id: entregador },
    }

    const {dynamicTable, ...resultEntity} = this.entity;

    let request: Observable<any>

    if (!resultEntity.id) {
      const {id, ...restResult} = resultEntity;
      request = this.http.post(ESTEIRA_PEDIDOS_ENTREGA, restResult)
    } else {
      request = this.http.put(ESTEIRA_PEDIDOS_ENTREGA, resultEntity)
    }

    request.subscribe(resp => {
      this.close()
    })
  }
}
