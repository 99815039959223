<dg-modal #modal title="Entregador" size="sm" (afterClose)="clear()">
  <div modal-body>
    <form [formGroup]="form">
      <div class="row">
        <div class="col-12">
          <label class="form-label">Nome</label>
          <input formControlName="nome" class="form-control">
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <label class="form-label">Placa</label>
          <input formControlName="placa" class="form-control">
        </div>
      </div>
    </form>
  </div>
  <div modal-footer class="d-inline-flex justify-content-center">
    <button (click)="close()" class="btn btn-secondary m-1">Sair</button>
    <button (click)="save()" class="btn btn-success m-1">Salvar</button>
  </div>
</dg-modal>
