import {FoodProdutoComplementosItemsEntity} from "./food-produto-complementos-items.entity";

export class FoodProdutoComplementos {

  constructor(
    id?: string,
    nome?: string,
    obrigatorio?: boolean,
    tipoSelecao?: string,
    limit?: string,
    items?: FoodProdutoComplementosItemsEntity[],
    isEdit?: boolean
  ) {
    this.id = id;
    this.nome = nome;
    this.obrigatorio = obrigatorio;
    this.tipoSelecao = tipoSelecao;
    this.limit = limit;
    this.items = Array.isArray(items) ? items : [];
    this.isEdit = isEdit;
  }

  id: string | undefined
  nome: string | undefined
  obrigatorio: boolean | undefined
  tipoSelecao: string | undefined
  limit: string | undefined
  items: FoodProdutoComplementosItemsEntity[] = [];
  isEdit: boolean | undefined
}
