class DiaTrabalhado {
  constructor(props?: {
    status?: boolean
    abertura?: string
    fechamento?: string
  }) {
    this.status = !!props?.status
    this.abertura = props?.abertura || ''
    this.fechamento = props?.fechamento || ''
  }
  status: boolean
  abertura: string
  fechamento: string
}

export class DiasTrabalhadosEntity {

  constructor(
    domingo?: DiaTrabalhado,
    segunda?: DiaTrabalhado,
    terca?: DiaTrabalhado,
    quarta?: DiaTrabalhado,
    quinta?: DiaTrabalhado,
    sexta?: DiaTrabalhado,
    sabado?: DiaTrabalhado,
  ) {
    this.domingo = new DiaTrabalhado(domingo)
    this.segunda = new DiaTrabalhado(segunda)
    this.terca = new DiaTrabalhado(terca)
    this.quarta = new DiaTrabalhado(quarta)
    this.quinta = new DiaTrabalhado(quinta)
    this.sexta = new DiaTrabalhado(sexta)
    this.sabado = new DiaTrabalhado(sabado)
  }

  domingo: DiaTrabalhado;
  segunda: DiaTrabalhado;
  terca: DiaTrabalhado;
  quarta: DiaTrabalhado;
  quinta: DiaTrabalhado;
  sexta: DiaTrabalhado;
  sabado: DiaTrabalhado;
}
