<div class="container">
  <div class="row">
    <div class="col-12">
      <div class="card">
        <div class="card-header">
          <h4 class="card-title">Estabelecimento</h4>
        </div>
        <div class="card-body">
          <form [formGroup]="form">
            <div class="row">
              <div class="col-3">
                <label class="form-label">Nome</label>
                <input class="form-control" formControlName="nome">
              </div>
              <div class="col-3">
                <label class="form-label">Cargo</label>
                <input class="form-control" formControlName="cargo">
              </div>
              <div class="col-3">
                <label class="form-label">Telefone</label>
                <input class="form-control" formControlName="telefone">
              </div>
              <div class="col-3">
                <label class="form-label">E-mail</label>
                <input class="form-control" formControlName="email">
              </div>
            </div>
          </form>
        </div>
        <div class="card-footer">
          <button crud-salvar class="btn btn-success">Salvar</button>
        </div>
      </div>
    </div>
  </div>

</div>
