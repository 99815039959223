import {Component, HostListener, OnInit} from '@angular/core';
import {CdkAbstractInsertEdit} from "@datagrupo/dg-crud";
import Swal from "sweetalert2";
import {SessionService} from "../../../services/session/session.service";
import {WebsocketService} from "../../../services/websocket/websocket.service";
import {HttpService} from "../../../services/http-service/http.service";
import {statusPedidoType} from "../../../enums/status-pedido.enum";
import {EMPRESA_EXPEDIENTE, ESTEIRA_PEDIDOS_ESTEIRA_COUNT} from "../../../core/endpoints";

@Component({
  selector: 'app-private-layout',
  templateUrl: './private-layout.component.html',
  styleUrls: ['./private-layout.component.scss']
})
export class PrivateLayoutComponent implements OnInit {

  numeroPedidosEmAnalise: number = 0;
  private notificationSound = new Audio('assets/sounds/notification-1.wav');

  constructor(
    public session: SessionService,
    private socketService: WebsocketService,
    private http: HttpService
  ) {
    socketService.socket.on('expediente-'+session.user.estabalecimento, resp => {
      this.session.expedienteAberto = resp;
      setTimeout(() => {
        alert('enviando')
        window.dispatchEvent(new CustomEvent('init-esteira-pedido'))
      }, 1000)
    })
    socketService.socket.on(
      'esteira-produtos-' + session.user.estabalecimento,
      (resp: { status: statusPedidoType[], novoProduto: boolean }) => {

        if (resp.status.includes('EM_ANALISE')) {
          this.findNovosPedidos(resp.novoProduto)
        }
      })
    this.http.get(EMPRESA_EXPEDIENTE).subscribe(resp => {
      this.session.expedienteAberto = resp.data
      window.dispatchEvent(new CustomEvent('init-esteira-pedido'))
    })
  }

  ngOnInit(): void {
  }

  findNovosPedidos(novoPedido?: boolean) {
    this.http.get(ESTEIRA_PEDIDOS_ESTEIRA_COUNT, {params: {status: 'EM_ANALISE'}}).subscribe(resp => {
      this.numeroPedidosEmAnalise = resp.data;
      if (!!novoPedido) {
        this.notificationSound?.play().then()
      }
    })
  }

  /**
   * Exibição de mensagens de retorno da dg-crud
   * @param ev
   */
  @HostListener('window:crud-callback-message', ['$event'])
  crudCallbackMessage(ev: CustomEvent<CdkAbstractInsertEdit.crudCallbackMessage>) {
    const data = ev.detail;
    if (data.origin == 'dg-crud') {
      if (!!data.message?.status) {
        Swal.fire({
          icon: !!data.message?.status ? 'success' : 'warning',
          title: data.message?.title || '',
          text: data.message?.text || ''
        }).then()
      } else {
        Swal.fire({
          icon: !!data.message?.message?.status ? 'success' : 'warning',
          title: data.message?.message?.title || '',
          text: data.message?.message?.text || ''
        }).then()
      }
      return;
    } else {
      Swal.fire({
        icon: 'warning',
        title: data.message?.error?.message || '',
      }).then()
    }
    console.log(data)
  }
}
