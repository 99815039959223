import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EntregadorMainComponent } from './entregador-main/entregador-main.component';
import { ModalEntregadorInsertEditComponent } from './modal-entregador-insert-edit/modal-entregador-insert-edit.component';
import {CdkDynamicTableModule, DgModalModule, DgPaginatorModule, DgTableModule} from "@datagrupo/dg-ng-util";
import {CdkTableModule} from "@angular/cdk/table";
import {ReactiveFormsModule} from "@angular/forms";



@NgModule({
  declarations: [
    EntregadorMainComponent,
    ModalEntregadorInsertEditComponent
  ],
  imports: [
    CommonModule,
    DgTableModule,
    CdkTableModule,
    CdkDynamicTableModule,
    DgPaginatorModule,
    DgModalModule,
    ReactiveFormsModule,
  ]
})
export class EntregadorModule { }
