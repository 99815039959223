import { Component, OnInit } from '@angular/core';
import {HttpService} from "../../../../../services/http-service/http.service";
import {EMPRESA, EMPRESA_FORMAS_PAGAMENTO} from "../../../../../core/endpoints";
import Swal from "sweetalert2";
import {SessionService} from "../../../../../services/session/session.service";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {HttpHelpersService} from "../../../../../services/http-helpers/http-helpers.service";

@Component({
  selector: 'app-forma-pagamento-main',
  templateUrl: './forma-pagamento-main.component.html',
  styleUrls: ['./forma-pagamento-main.component.scss']
})
export class FormaPagamentoMainComponent implements OnInit {

  formasPagamento: string[] = [];

  formEmpresa = new FormGroup({
    nome: new FormControl('', [Validators.required]),
    link: new FormControl('', [Validators.required]),
    cep: new FormControl('', [Validators.required]),
    logradouro: new FormControl('', [Validators.required]),
    numero: new FormControl('', [Validators.required]),
    complemento: new FormControl(''),
    bairro: new FormControl(''),
    cidade: new FormControl(''),
    estado: new FormControl(''),
  });

  formHorariosFuncionamento = new FormGroup({
    domingoStatus: new FormControl(false),
    domingoAbertura: new FormControl(''),
    domingoFechamento: new FormControl(''),

    segundaStatus: new FormControl(false),
    segundaAbertura: new FormControl(''),
    segundaFechamento: new FormControl(''),

    tercaStatus: new FormControl(false),
    tercaAbertura: new FormControl(''),
    tercaFechamento: new FormControl(''),

    quartaStatus: new FormControl(false),
    quartaAbertura: new FormControl(''),
    quartaFechamento: new FormControl(''),

    quintaStatus: new FormControl(false),
    quintaAbertura: new FormControl(''),
    quintaFechamento: new FormControl(''),

    sextaStatus: new FormControl(false),
    sextaAbertura: new FormControl(''),
    sextaFechamento: new FormControl(''),

    sabadoStatus: new FormControl(false),
    sabadoAbertura: new FormControl(''),
    sabadoFechamento: new FormControl(''),
  })

  view: 'marca' | 'configuracoes' | 'layout' = 'marca';

  constructor(
    private http: HttpService,
    private session: SessionService,
    private httpHelper: HttpHelpersService,
  ) {
    this.formEmpresa.controls['cep'].valueChanges.subscribe((resp: any) => {
      this.httpHelper.getCep(resp, value => {
        this.formEmpresa.patchValue({
          logradouro: value.logradouro,
          bairro: value.bairro,
          cidade: value.localidade,
          estado: value.uf,
        })
      })
    })
    this.http.get(EMPRESA+'/'+ session.user.estabalecimento).subscribe(
      resp => {
        this.formEmpresa.patchValue(resp.data)
        this.formasPagamento = (resp.data?.formasPagamento || '').split(',');
      }
    )
  }

  ngOnInit(): void {
    this.formHorariosFuncionamento.controls['domingoStatus'].valueChanges.subscribe((resp: any) => {
      this.formHorariosFuncionamento.controls['domingoAbertura'][!!resp ? 'enable' : 'disable']()
      this.formHorariosFuncionamento.controls['domingoFechamento'][!!resp ? 'enable' : 'disable']()
    })
    this.formHorariosFuncionamento.controls['segundaStatus'].valueChanges.subscribe((resp: any) => {
      this.formHorariosFuncionamento.controls['segundaAbertura'][!!resp ? 'enable' : 'disable']()
      this.formHorariosFuncionamento.controls['segundaFechamento'][!!resp ? 'enable' : 'disable']()
    })
    this.formHorariosFuncionamento.controls['tercaStatus'].valueChanges.subscribe((resp: any) => {
      this.formHorariosFuncionamento.controls['tercaAbertura'][!!resp ? 'enable' : 'disable']()
      this.formHorariosFuncionamento.controls['tercaFechamento'][!!resp ? 'enable' : 'disable']()
    })
    this.formHorariosFuncionamento.controls['quartaStatus'].valueChanges.subscribe((resp: any) => {
      this.formHorariosFuncionamento.controls['quartaAbertura'][!!resp ? 'enable' : 'disable']()
      this.formHorariosFuncionamento.controls['quartaFechamento'][!!resp ? 'enable' : 'disable']()
    })
    this.formHorariosFuncionamento.controls['quintaStatus'].valueChanges.subscribe((resp: any) => {
      this.formHorariosFuncionamento.controls['quintaAbertura'][!!resp ? 'enable' : 'disable']()
      this.formHorariosFuncionamento.controls['quintaFechamento'][!!resp ? 'enable' : 'disable']()
    })
    this.formHorariosFuncionamento.controls['sextaStatus'].valueChanges.subscribe((resp: any) => {
      this.formHorariosFuncionamento.controls['sextaAbertura'][!!resp ? 'enable' : 'disable']()
      this.formHorariosFuncionamento.controls['sextaFechamento'][!!resp ? 'enable' : 'disable']()
    })
    this.formHorariosFuncionamento.controls['sabadoStatus'].valueChanges.subscribe((resp: any) => {
      this.formHorariosFuncionamento.controls['sabadoAbertura'][!!resp ? 'enable' : 'disable']()
      this.formHorariosFuncionamento.controls['sabadoFechamento'][!!resp ? 'enable' : 'disable']()
    })
    this.formHorariosFuncionamento.patchValue({
      segundaStatus: true,
      tercaStatus: true,
      quartaStatus: true,
      quintaStatus: true,
      sextaStatus: true,
      sabadoStatus: true,
    })
  }

  clickSelect(value: string) {
    const index = this.formasPagamento.findIndex(forma => forma == value);

    if (index >= 0) {
      this.formasPagamento.splice(index, 1)
    } else {
      this.formasPagamento.push(value)
    }
  }

  isSelected(value: string) {
    return this.formasPagamento.findIndex(forma => forma == value) >= 0;
  }

  save() {
    if (this.formEmpresa.invalid) {
      this.formEmpresa.markAllAsTouched();
      return;
    }

    if (this.formasPagamento.length <= 0) {
      Swal.fire({
        icon: 'warning',
        title: 'Selecione ao menos uma forma de pagamento'
      }).then()
      return;
    }

    const data = {
      id: this.session.user.estabalecimento,
      ...this.formEmpresa.value,
      formasPagamento: this.formasPagamento
    }

    this.http.put(EMPRESA, data).subscribe(
      resp => {
        Swal.fire({
          icon: 'success',
          title: 'Formas de pagamento atualizadas'
        }).then()
      },
    )
  }

}
