import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EmpresaInsertEditComponent } from './empresa-insert-edit/empresa-insert-edit.component';
import { EmpresaMainComponent } from './empresa-main/empresa-main.component';
import {CdkDynamicTableModule, DgPaginatorModule, DgTableModule} from "@datagrupo/dg-ng-util";
import {RouterModule} from "@angular/router";



@NgModule({
  declarations: [
    EmpresaInsertEditComponent,
    EmpresaMainComponent
  ],
  imports: [
    CommonModule,
    DgTableModule,
    DgPaginatorModule,
    CdkDynamicTableModule,
    RouterModule
  ]
})
export class EmpresaModule { }
