import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-site-agendamento-layout',
  templateUrl: './site-agendamento-layout.component.html',
  styleUrls: ['./site-agendamento-layout.component.scss']
})
export class SiteAgendamentoLayoutComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
