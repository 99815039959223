<div class="card card-pedido-esteira" [class.selected]="!!this.data['selectedItem']" (click)="_clickSelect($event)">
  <div
    class="card-header d-inline-flex justify-content-between"
    cdkDrag
  >
    <h6 class="card-title">{{data?.cliente?.nome || '--'}}</h6>
    <p *ngIf="!!data?.endereco">{{data.endereco.rua}}</p>
  </div>
  <div class="card-header d-inline-flex justify-content-between">
    <span class="card-title">R$ {{data?.total}}</span>
    <span style="size: 9pt; color: grey">{{data.produtos.length}} itens</span>
  </div>
  <div class="card-footer d-inline-flex justify-content-between">
    <button [disabled]="data?.selectedItem" (click)="modalPedido.open(data)" class="btn btn-info btn-sm">Abrir</button>
    <button [disabled]="data?.selectedItem" *ngIf="data?.status == 'EM_ANALISE'" (click)="changeStatus(data, 'PRODUCAO')" class="btn btn-success btn-sm">Aceitar</button>
    <button [disabled]="data?.selectedItem" *ngIf="data?.status == 'PRODUCAO'" (click)="changeStatus(data, 'PRONTO')" class="btn btn-success btn-sm">Pronto</button>
<!--    <button [disabled]="data?.selectedItem" *ngIf="data?.status == 'PRONTO'" (click)="changeStatus(data, 'CAMINHO')" class="btn btn-success btn-sm">A caminho</button>-->
    <button [disabled]="data?.selectedItem" *ngIf="data?.status == 'CAMINHO'" (click)="changeStatus(data, 'ENTREGUE')" class="btn btn-success btn-sm">Finalizado</button>
  </div>
</div>


<modal-esteira-pedido #modalPedido>
</modal-esteira-pedido>
