import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FoodEsteiraPedidosMainComponent} from './food-esteira-pedidos-main/food-esteira-pedidos-main.component';
import {CardPedidoEsteiraComponent} from './sub-components/card-pedido-esteira/card-pedido-esteira.component';
import {ModalEsteiraPedidoComponent} from './sub-components/modal-esteira-pedido/modal-esteira-pedido.component';
import {DgModalModule} from "@datagrupo/dg-ng-util";
import {DragDropModule} from "@angular/cdk/drag-drop";
import {ModalEsteiraEntregaComponent} from './sub-components/modal-esteira-entrega/modal-esteira-entrega.component';
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import { CardEntregaPedidoEsteiraComponent } from './sub-components/card-entrega-pedido-esteira/card-entrega-pedido-esteira.component';
import { ModalEsteiraEntregaAndamentoComponent } from './sub-components/modal-esteira-entrega-andamento/modal-esteira-entrega-andamento.component';


@NgModule({
  declarations: [
    FoodEsteiraPedidosMainComponent,
    CardPedidoEsteiraComponent,
    ModalEsteiraPedidoComponent,
    ModalEsteiraEntregaComponent,
    CardEntregaPedidoEsteiraComponent,
    ModalEsteiraEntregaAndamentoComponent
  ],
    imports: [
        CommonModule,
        DgModalModule,
        DragDropModule,
        ReactiveFormsModule,
        FormsModule
    ]
})
export class FoodEsteiraPedidosModule {
}
