<div class="container">
  <div class="row">
    <div class="col-12">
      <div class="card">
        <div class="card-header">
          <h4 class="card-title">Serviços</h4>
        </div>
        <div class="card-body">
          <form [formGroup]="form">
            <div class="row">
              <div class="col-3">
                <label class="form-label">nome</label>
                <input class="form-control" formControlName="nome">
              </div>
              <div class="col-3">
                <label class="form-label">tempo</label>
                <input class="form-control" formControlName="tempo">
              </div>
              <div class="col-3">
                <label class="form-label">colaborador</label>
                <mat-select formControlName="colaboradores" multiple>
                  <mat-option  *ngFor="let item of listColaboradores" [value]="item.id">{{item.nome}}</mat-option>
                </mat-select>
              </div>
              <div class="col-3">
                <label class="form-label">valor</label>
                <input class="form-control" formControlName="valor">
              </div>
              <div class="col-3">
                <label class="form-label">comissao</label>
                <input class="form-control" formControlName="comissao">
              </div>
              <div class="col-3">
                <label class="form-label">categorias</label>
                <mat-select formControlName="categorias" multiple>
                  <mat-option  *ngFor="let item of listCategorias" [value]="item.id">{{item.nome}}</mat-option>
                </mat-select>
              </div>
            </div>
          </form>
        </div>
        <div class="card-footer">
          <button crud-salvar class="btn btn-success">Salvar</button>
        </div>
      </div>
    </div>
  </div>

</div>
