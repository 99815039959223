import { Component, OnInit } from '@angular/core';
import {HttpService} from "../../../services/http-service/http.service";
import {FormControl, FormGroup, Validators} from "@angular/forms";

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  form = new FormGroup({
    username: new FormControl('', [Validators.required]),
    password: new FormControl('', [Validators.required]),
  })

  constructor(private service: HttpService) { }

  ngOnInit(): void {
  }

  logar() {
    this.service.post('v1/auth/login', this.form.value).subscribe(resp => {
      window.localStorage.setItem('accessToken', resp.data.access_token)
      window.location.href = '/auth/start-session'
    })
  }

}
