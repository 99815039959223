<div id="public-layout">
  <nav class="navbar navbar-expand-lg bg-light">
    <div class="container-fluid">
      <a class="navbar-brand" href="#">Navbar</a>
      <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse" id="navbarNav">
        <ul class="navbar-nav">
          <li class="nav-item">
            <a class="nav-link active" aria-current="page" href="#">Home</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" routerLink="/login">Login</a>
          </li>
<!--          <li class="nav-item">-->
<!--            <a class="nav-link" routerLink="/site/home">Site de agendamento</a>-->
<!--          </li>-->
          <li class="nav-item">
            <a class="nav-link" routerLink="cadastre-se">cadastre-se</a>
          </li>
        </ul>
      </div>
    </div>
  </nav>

  <div class="public-content-layout">
    <router-outlet></router-outlet>
  </div>
</div>
