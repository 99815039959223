import { Component, OnInit } from '@angular/core';
import {AbstractInsertEdit2, InsertEditConfig2} from "@datagrupo/dg-crud";
import {CategoriaEntity} from "../../../categoria/categoria.entity";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {HttpService} from "../../../../../services/http-service/http.service";
import {
  FOOD_CATEGORIA,
  FOOD_PRODUTO_COMPLEMENTOS_LIST,
  FOOD_PRODUTO_UPLOAD_IMAGE
} from "../../../../../core/endpoints";
import {FoodProdutoEntity} from "../food-produto.entity";
import {FoodCategoriaEntity} from "../../food-categorias/food-categoria.entity";
import {SessionService} from "../../../../../services/session/session.service";
import {replaceEndpointParams} from "../../../../../helpers/endpoints-helpers";
import {FoodProdutoComplementos} from "../food-produto-complementos";

@Component({
  selector: 'app-food-produtos-insert-edit',
  templateUrl: './food-produtos-insert-edit.component.html',
  styleUrls: ['./food-produtos-insert-edit.component.scss']
})
export class FoodProdutosInsertEditComponent extends AbstractInsertEdit2<FoodProdutoEntity> implements OnInit {

  rootEntity = new FoodProdutoEntity();
  listCategorias: FoodCategoriaEntity[] = [];
  complementos: FoodProdutoComplementos[] = [];
  file: File | undefined;

  form = new FormGroup({
    nome: new FormControl('', [Validators.required]),
    valor: new FormControl('', [Validators.required]),
    categoria: new FormControl(''),
    descricao: new FormControl(''),
  })

  constructor(
    public config: InsertEditConfig2, private http: HttpService,
    private session: SessionService
  ) {
    super(config)
    http.get(FOOD_CATEGORIA).subscribe(resp => {
      this.listCategorias = resp.data;
    })
  }

  override ngOnInit(): void {
    super.ngOnInit();
  }

  override fetchEntity() {
    this.http.get(replaceEndpointParams(FOOD_PRODUTO_COMPLEMENTOS_LIST, this.entityId)).subscribe(resp => {
      this.complementos = Array.isArray(resp.data) ? resp.data : [];
    })
    super.fetchEntity();
  }

  override afterFetchEntity(entityData: any) {
    this.form.patchValue({
      ...entityData,
      categoria: entityData?.categoria?.id || '',
    })
    if (!this.entity.complementos) this.entity.complementos = [];
    this.entity.complementos.map(item => {
      if (!item.items) item['items'] = [];
      return item;
    })
  }

  override beforeSaveEntity(): boolean {
    if (this.form.invalid) {
      this.form.markAllAsTouched();
      return false;
    }

    const {categoria, ...form} = this.form.value;

    this.entity = {
      ...this.entity,
      ...form,
      complementos: this.complementos,
      empresa: { id: this.session.user.estabalecimento},
      categoria: { id: categoria },
    }

    return true;
  }


  uploadFile(event?: Event) {
    //@ts-ignore
    if (!event.target.files) {
      return;
    }
    //@ts-ignore
    const file: File = event.target.files[0];

    if (!file) return;

    const data = new FormData();
    data.set('file', file);

    this.http.post(replaceEndpointParams(FOOD_PRODUTO_UPLOAD_IMAGE, <string>this.entity.id), data).subscribe(resp => {
      this.file = undefined;
      this.entity.imageUrl = resp.imageUrl
    })
  }

}
