import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FoodProdutosInsertEditComponent } from './food-produtos-insert-edit/food-produtos-insert-edit.component';
import { FoodProdutosMainComponent } from './food-produtos-main/food-produtos-main.component';
import {CdkDynamicTableModule, DgPaginatorModule, DgTableModule} from "@datagrupo/dg-ng-util";
import {RouterModule} from "@angular/router";
import {MatSelectModule} from "@angular/material/select";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {DgCrudModule} from "@datagrupo/dg-crud";
import { FichaProdutoComponent } from './sub-components/ficha-produto/ficha-produto.component';



@NgModule({
  declarations: [
    FoodProdutosInsertEditComponent,
    FoodProdutosMainComponent,
    FichaProdutoComponent
  ],
  imports: [
    CommonModule,
    DgTableModule,
    DgPaginatorModule,
    CdkDynamicTableModule,
    RouterModule,
    MatSelectModule,
    ReactiveFormsModule,
    DgCrudModule,
    FormsModule
  ]
})
export class FoodProdutosModule { }
