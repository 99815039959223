<div class="card">
  <div class="card-header">
    <h4 class="card-title">Lista de insumos</h4>
  </div>
  <div class="card-header">
    <button (click)="addGrupo()" class="btn btn-success">Adicionar grupo</button>
  </div>
  <div class="card-body">
<!--    <div class="row" *ngFor="let item of complementos; let index = index">-->
<!--      <div class="col-12">-->
<!--        <div class="card">-->
<!--          <div class="card-header">-->
<!--            <div class="row">-->
<!--              <div class="col-4">-->
<!--                <div class="input-group mb-3">-->
<!--                  <input class="form-control" [disabled]="!item.isEdit" [(ngModel)]="item.nome">-->
<!--                  <span class="input-group-text" id="basic-addon2">editar</span>-->
<!--                </div>-->
<!--              </div>-->
<!--              <div class="col-4">-->
<!--                <button-->
<!--                  class="btn"-->
<!--                  [class.btn-secondary]="!item.isEdit"-->
<!--                  [class.btn-success]="item.isEdit"-->
<!--                  (click)="item['isEdit'] = !item.isEdit"-->
<!--                >-->
<!--                  {{item.isEdit ? 'Salvar' : 'Editar'}}-->
<!--                </button>-->
<!--              </div>-->
<!--            </div>-->
<!--            <div class="row">-->
<!--              <div class="col-3">-->
<!--                <label class="form-label">Obrigatório</label>-->
<!--                <select class="form-select" [disabled]="!item.isEdit" [(ngModel)]="item.obrigatorio">-->
<!--                  <option [ngValue]="false">Não</option>-->
<!--                  <option [ngValue]="true">Sim</option>-->
<!--                </select>-->
<!--              </div>-->
<!--              <div class="col-3">-->
<!--                <label class="form-label">Tipo de selação</label>-->
<!--                <select class="form-select" [disabled]="!item.isEdit" [(ngModel)]="item.tipoSelecao">-->
<!--                  <option value="MULTIPLO">Multiplo</option>-->
<!--                  <option value="UNITARIO">Uma entre essas opções</option>-->
<!--                </select>-->
<!--              </div>-->
<!--              <div class="col-3">-->
<!--                <label class="form-label">Máximo de itens</label>-->
<!--                <input class="form-control" type="number" value="1" [disabled]="!item.isEdit" [(ngModel)]="item.limit">-->
<!--              </div>-->
<!--              <div class="col-3">-->
<!--                <button (click)="addProdutoItem(index)" class="btn btn-success">Adicionar item</button>-->
<!--              </div>-->
<!--            </div>-->
<!--          </div>-->
<!--          <div class="card-body">-->
<!--            <ul class="list-group">-->
<!--              <li *ngFor="let itemProduto of item.items" class="list-group-item">-->
<!--                <div class="row">-->
<!--                  <div class="col-4">-->
<!--                    <label class="form-label">Nome</label>-->
<!--                    <input class="form-control" [disabled]="!item.isEdit" [(ngModel)]="itemProduto.nome">-->
<!--                  </div>-->
<!--                  <div class="col-2">-->
<!--                    <label class="form-label">Máximo de itens</label>-->
<!--                    <input class="form-control" min="1" type="number" [disabled]="!item.isEdit"-->
<!--                           [(ngModel)]="itemProduto.limit">-->
<!--                  </div>-->
<!--                  <div class="col-2">-->
<!--                    <label class="form-label">Tipo custo</label>-->
<!--                    <select class="form-select" [disabled]="!item.isEdit" [(ngModel)]="itemProduto.tipoCusto">-->
<!--                      <option value="SEM_CUSTO">Sem custo</option>-->
<!--                      <option value="EXCEDENTE_LIMIT">Excedendo o máximo</option>-->
<!--                      <option value="ADICIONAL">Adicional</option>-->
<!--                    </select>-->
<!--                  </div>-->
<!--                  <div class="col-2" *ngIf="itemProduto.tipoCusto !='SEM_CUSTO'">-->
<!--                    <label class="form-label">Custo adicional</label>-->
<!--                    <input class="form-control" min="1" type="number" [disabled]="!item.isEdit"-->
<!--                           [(ngModel)]="itemProduto.custoAdicional">-->
<!--                  </div>-->
<!--                  <div class="col-2" *ngIf="itemProduto.tipoCusto =='EXCEDENTE_LIMIT'">-->
<!--                    <label class="form-label">Máximo de extras</label>-->
<!--                    <input class="form-control" min="1" type="number" [disabled]="!item.isEdit"-->
<!--                           [(ngModel)]="itemProduto.limitExtras">-->
<!--                  </div>-->
<!--                </div>-->
<!--              </li>-->
<!--            </ul>-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->
<!--    </div>-->
    <div class="accordion">
      <div class="accordion-item" *ngFor="let item of complementos; let index = index">
        <h2 class="accordion-header" [attr.id]="'complementos-list-item-id-'+index">
          <button
            class="accordion-button" type="button" data-bs-toggle="collapse"
            [attr.data-bs-target]="'#complementos-list-id-'+index" aria-expanded="true"
            [attr.aria-controls]="'complementos-list-id-'+index"
          >
            {{item.nome || 'complementos'}}
          </button>
        </h2>
        <div
          [attr.id]="'complementos-list-id-'+index" class="accordion-collapse collapse show"
          [attr.aria-labelledby]="'complementos-list-id-'+index"
        >
          <div class="accordion-body">
            <div class="row">
              <div class="col-4">
                <div class="input-group mb-3">
                  <input class="form-control" [(ngModel)]="item.nome">
                  <span class="input-group-text">editar</span>
                </div>
              </div>
              <div class="col-3 d-inline-flex justify-content-end ms-auto">
                <div>
                  <button (click)="removeComplemento(item, index)" class="btn btn-danger">Remover</button>
                </div>
              </div>
            </div>
            <div class="row mt-3">
              <div class="col-3">
                <label class="form-label">Obrigatório</label>
                <select class="form-select" [(ngModel)]="item.obrigatorio">
                  <option [ngValue]="false">Não</option>
                  <option [ngValue]="true">Sim</option>
                </select>
              </div>
              <div class="col-3">
                <label class="form-label">Tipo de selação</label>
                <select class="form-select" [(ngModel)]="item.tipoSelecao">
                  <option value="MULTIPLO">Multiplo</option>
                  <option value="UNITARIO">Uma entre essas opções</option>
                </select>
              </div>
              <div class="col-3">
                <label class="form-label">Máximo de itens</label>
                <input class="form-control" type="number" value="1" [(ngModel)]="item.limit">
              </div>
              <div class="col-3">
                <button (click)="addProdutoItem(index)" class="btn btn-success">Adicionar item</button>
              </div>
            </div>
          </div>
          <div class="accordion-body">
            <ul class="list-group">
              <li *ngFor="let itemProduto of item.items; let indexItem = index" class="list-group-item">
                <div class="row">
                  <div class="col-1 d-inline-flex justify-content-end ms-auto">
                    <div>
                      <button (click)="removeItem(item, itemProduto, indexItem)" class="btn btn-danger">-</button>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-3">
                    <label class="form-label">Nome</label>
                    <input class="form-control" [(ngModel)]="itemProduto.nome">
                  </div>
                  <div class="col-2">
                    <label class="form-label">Máximo de itens</label>
                    <input class="form-control" min="1" type="number"
                           [(ngModel)]="itemProduto.limit">
                  </div>
                  <div class="col-2">
                    <label class="form-label">Tipo custo</label>
                    <select class="form-select" [(ngModel)]="itemProduto.tipoCusto">
                      <option value="SEM_CUSTO">Sem custo</option>
                      <option value="EXCEDENTE_LIMIT">Excedendo o máximo</option>
                      <option value="ADICIONAL">Adicional</option>
                    </select>
                  </div>
                  <div class="col-2" *ngIf="itemProduto.tipoCusto !='SEM_CUSTO'">
                    <label class="form-label">Custo adicional</label>
                    <input class="form-control" min="1" type="number"
                           [(ngModel)]="itemProduto.custoAdicional">
                  </div>
                  <div class="col-2" *ngIf="itemProduto.tipoCusto =='EXCEDENTE_LIMIT'">
                    <label class="form-label">Máximo de extras</label>
                    <input class="form-control" min="1" type="number"
                           [(ngModel)]="itemProduto.limitExtras">
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>

</div>
