import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ClientesMainComponent } from './clientes-main/clientes-main.component';
import { ClientesInsertEditComponent } from './clientes-insert-edit/clientes-insert-edit.component';
import {CdkDynamicTableModule, DgPaginatorModule, DgTableModule} from "@datagrupo/dg-ng-util";
import {ReactiveFormsModule} from "@angular/forms";
import {DgCrudModule} from "@datagrupo/dg-crud";
import {RouterModule} from "@angular/router";
import {NgxMaskModule} from "ngx-mask";



@NgModule({
  declarations: [
    ClientesMainComponent,
    ClientesInsertEditComponent
  ],
    imports: [
        CommonModule,
        DgPaginatorModule,
        DgTableModule,
        CdkDynamicTableModule,
        ReactiveFormsModule,
        DgCrudModule,
        RouterModule,
        NgxMaskModule
    ]
})
export class ClientesModule { }
