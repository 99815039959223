import {AbstractEntity2, CrudEntity} from "@datagrupo/dg-crud";
import {environment} from "../../../../environments/environment";
import {CATEGORIA, CLIENTE, ESTABELECIMENTO} from "../../../core/endpoints";
import {DynamicColumn, DynamicTableEntity} from "@datagrupo/dg-ng-util";

@CrudEntity({
  api: {
    path: environment.apiUrl,
    context: CATEGORIA
  }
})
@DynamicTableEntity({
  api: {
    path: environment.apiUrl,
    context: CATEGORIA
  }
})
export class CategoriaEntity extends AbstractEntity2 {

  constructor(
    nome?: string,
  ) {
    super();
    this.nome = nome;
  }

  @DynamicColumn({ headerName: 'Nome' })
  nome: string | undefined
}
