<div class="container">
  <div class="row">
    <div class="col-12">
      <div class="card">
        <div class="card-header">
          <h4 class="card-title">Serviços</h4>
        </div>
        <div class="card-body">
          <dg-table dg-dynamic-table [dynamicTable]="table"></dg-table>
        </div>
        <div class="card-footer">
          <dg-paginator dg-dynamic-paginator [dynamicTable]="table"></dg-paginator>
        </div>
        <div class="card-footer">
          <button routerLink="novo" class="btn btn-success">Novo</button>
        </div>
      </div>
    </div>
  </div>
</div>
