import {NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {LayoutsModule} from "./layouts/layouts.module";
import {PublicPagesModule} from "./pages/public/public-pages.module";
import {PrivatePagesModule} from "./pages/private/private-pages.module";
import {HttpClientModule} from "@angular/common/http";
import {RouterModule} from "@angular/router";
import {ConfigDgTable, ConfigDynamicTable} from "@datagrupo/dg-ng-util";
import {configLocalDynamicTable} from "./core/config/dg-ng-util/config-local-dynamic-table";
import {configLocalDgTable} from "./core/config/dg-ng-util/config-local-dg-table";
import {ConfigLocalDgCrud} from "./core/config/dg-crud/config-local-dg-crud";
import {ConfigDgCurd} from "@datagrupo/dg-crud";
import {registerLocaleData} from "@angular/common";
import {IConfig, NgxMaskModule} from "ngx-mask";
import localePt from '@angular/common/locales/pt';


ConfigDynamicTable.set(configLocalDynamicTable)
ConfigDgTable.set(configLocalDgTable)
ConfigDgCurd.set(ConfigLocalDgCrud)

registerLocaleData(localePt);

// config para não impor validação em mascaras NGX-MASK
const maskConfig: Partial<IConfig> = {
  validation: false,
};

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    LayoutsModule,
    PublicPagesModule,
    PrivatePagesModule,
    HttpClientModule,
    RouterModule,
    NgxMaskModule.forRoot(maskConfig),
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule {
}
