import {  Routes } from '@angular/router';
import {PublicHomeComponent} from "./public-home/public-home.component";
import {LoginComponent} from "./login/login.component";
import {LoginGuard} from "../../core/guards/login/login.guard";
import {SignupComponent} from "./signup/signup.component";

export const publicRoutes: Routes = [
  { path: '', component: PublicHomeComponent },
  { path: 'cadastre-se', component: SignupComponent},
  { path: 'login', component: LoginComponent, canActivate: [LoginGuard] },
];
