<div id="site-venda-banner">
</div>

<div id="site-venda-apresentacao" class="container">
  <div class="row">
    <div class="col-xl-2 col-lg-3 col-md-5 col-sm-6 col-xs-12">
      <img class="logo-estabelecimento" src="assets/images/default/default-placeholder.png">
    </div>
    <div class="col-xl-10 col-lg-9 col-md-7 col-sm-6 col-xs-12">
      Informações do estabelecimento
    </div>
  </div>
</div>

<router-outlet></router-outlet>
