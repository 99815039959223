<div id="esteira-fechada" class="container-fluid" *ngIf="!session.expedienteAberto">
  <div class="row justify-content-center">
    <div class="col-6">
      <div class="card w-100">
        <div class="card-header">
          <h4 class="card-title">Abrir loja</h4>
        </div>
        <div class="card-body">
          <p class="card-text">
            Para acessar a esteira de pedidos é necessário iniciar um expediente.
            Clique em "abrir loja"
          </p>
        </div>
        <div class="card-footer d-inline-flex justify-content-center">
          <button class="btn btn-success" (click)="iniciarExpediente()">Abrir loja</button>
        </div>
      </div>
    </div>
  </div>
</div>


<div id="esteira-info">
  <div class="container-fluid">
    <div class="row">
      <div class="col-10">
        <div class="row">
          <div class="col-12">
            <label class="">Expediente aberto em: <span>{{session.expedienteAberto?.dataInicio}}</span></label>
          </div>
          <div class="col-12">
            <label class="">Total de entregadores: <span>2</span></label>
          </div>
          <div class="col-12">
            <label class="">Entregadores disponíveis: <span>1</span></label>
          </div>
        </div>
      </div>
      <div class="col-2 d-inline-flex justify-content-end">
        <div>
          <button
            (click)="finalizarExpediente()"
            class="btn btn-sm btn-danger m-2"
          >
            Finalizar Expediente
          </button>
        </div>
      </div>
    </div>
  </div>
</div>

<div id="esteira-pedidos-main">
  <div id="content-enteira" class="container-fluid">
    <div class="row">
      <div class="col-status-esteira-pedidos">
        <div class="card card-content-status-pedido-esteira">
          <div class="card-header">
            <h5 class="card-title">Em analise</h5>
            <span>Selecionar multiplos</span>
          </div>
          <div
            class="card-body"
            cdkDropList
            id="list-EM_ANALISE"
            #analiseList="cdkDropList"
            [cdkDropListData]="esteiraService.list_status_pedido.EmAnalise"
            [cdkDropListConnectedTo]="[producaoList]"
            (cdkDropListDropped)="drop($event)"
          >
            <div class="row mt-1" *ngFor="let item of esteiraService.list_status_pedido.EmAnalise">
              <div class="col-12">
                <card-pedido-esteira
                  [data]="item"
                  (clickSelect)="esteiraService.clickSelectPedidoEvent(esteiraService.list_status_pedido.EmAnalise, item.id, $event)"
                ></card-pedido-esteira>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-status-esteira-pedidos">
        <div class="card card-content-status-pedido-esteira">
          <div class="card-header">
            <h5 class="card-title">Em produção</h5>
          </div>
          <div
            class="card-body"
            cdkDropList
            id="list-PRODUCAO"
            #producaoList="cdkDropList"
            [cdkDropListData]="esteiraService.list_status_pedido.Producao"
            [cdkDropListConnectedTo]="[analiseList, prontoList]"
            (cdkDropListDropped)="drop($event)"
          >
            <div class="row mt-1" *ngFor="let item of esteiraService.list_status_pedido.Producao">
              <div class="col-12">
                <card-pedido-esteira
                  [data]="item"
                  (clickSelect)="esteiraService.clickSelectPedidoEvent(esteiraService.list_status_pedido.Producao, item.id, $event)"
                ></card-pedido-esteira>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-status-esteira-pedidos">
        <div class="card card-content-status-pedido-esteira">
          <div class="card-header">
            <h5 class="card-title">Pedido pronto</h5>
          </div>
          <div
            class="card-body"
            cdkDropList
            id="list-PRONTO"
            #prontoList="cdkDropList"
            [cdkDropListData]="esteiraService.list_status_pedido.Pronto"
            [cdkDropListConnectedTo]="[producaoList, caminhoList]"
            (cdkDropListDropped)="drop($event)"
          >
            <div class="row mt-1" *ngFor="let item of esteiraService.list_status_pedido.Pronto">
              <div class="col-12">
                <card-pedido-esteira
                  [data]="item"
                  (clickSelect)="esteiraService.clickSelectPedidoEvent(esteiraService.list_status_pedido.Pronto, item.id, $event)"
                ></card-pedido-esteira>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-status-esteira-pedidos">
        <div class="card card-content-status-pedido-esteira">
          <div class="card-header">
            <h5 class="card-title">Preparação para entrega</h5>
          </div>
          <div
            class="card-body"
            cdkDropList
            id="list-PREPARACAO-ENTREGA"
          >
            <div class="row mt-1">
              <div class="col-12">
                <div
                  class="card-header pointer"
                  (click)="modalEntrega.open()"
                >
                  <h6 class="card-title">+ Preparar nova viagem</h6>
                </div>
              </div>
            </div>

            <div class="row mt-1" *ngFor="let item of esteiraService.list_status_pedido.preparandoEntrega">
              <div class="col-12">
                <!--                <card-pedido-esteira-->
                <!--                  [data]="item"-->
                <!--                  (clickSelect)="esteiraService.clickSelectPedidoEvent(esteiraService.list_status_pedido.ACaminho, item.id, $event)"-->
                <!--                ></card-pedido-esteira>-->
                <card-entrega-pedido-esteira
                  [data]="item"
                  (open)="modalEntrega.open(item)"
                ></card-entrega-pedido-esteira>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-status-esteira-pedidos">
        <div class="card card-content-status-pedido-esteira">
          <div class="card-header">
            <h5 class="card-title">Pedido a caminho</h5>
          </div>
          <div
            class="card-body"
            cdkDropList
            id="list-CAMINHO"
            #caminhoList="cdkDropList"
            [cdkDropListData]="esteiraService.list_status_pedido.ACaminho"
            [cdkDropListConnectedTo]="[prontoList]"
            (cdkDropListDropped)="drop($event)"
          >
            <div class="row mt-1" *ngFor="let item of esteiraService.list_status_pedido.ACaminho">
              <div class="col-12">
                <card-entrega-pedido-esteira
                  [data]="item"
                ></card-entrega-pedido-esteira>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-status-esteira-pedidos">
        <div class="card card-content-status-pedido-esteira">
          <div class="card-header">
            <h5 class="card-title">Pedido entregue</h5>
          </div>
          <div
            class="card-body"
            cdkDropList
            #entregeList="cdkDropList"
            id="list-ENTREGUE"
            [cdkDropListData]="esteiraService.list_status_pedido.Entrege"
            [cdkDropListConnectedTo]="[caminhoList]"
            (cdkDropListDropped)="drop($event)"
          >
            <div class="row mt-1" *ngFor="let item of esteiraService.list_status_pedido.Entrege">
              <div class="col-12">
                <card-pedido-esteira
                  [data]="item"
                  (clickSelect)="esteiraService.clickSelectPedidoEvent(esteiraService.list_status_pedido.Entrege, item.id, $event)"
                ></card-pedido-esteira>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<modal-esteira-entrega #modalEntrega>
</modal-esteira-entrega>

