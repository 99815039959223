import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {PrivateLayoutComponent} from "./layouts/private/private-layout/private-layout.component";
import {PublicLayoutComponent} from "./layouts/public/public-layout/public-layout.component";
import {PrivateRouters} from "./pages/private/private-routing.module";
import {publicRoutes} from "./pages/public/public-routing.module";
import {
  SiteAgendamentoHomeComponent
} from "./pages/public/site-agendamento/site-agendamento-home/site-agendamento-home.component";
import {
  SiteAgendamentoLayoutComponent
} from "./layouts/site-agendamento/site-agendamento-layout/site-agendamento-layout.component";
import {StartSessionComponent} from "./pages/auth/start-session/start-session.component";
import {StartSessionGuard} from "./core/guards/start-session/start-session.guard";
import {SessionGuard} from "./core/guards/session/session.guard";

const routes: Routes = [

  {
    path: '',
    component: PublicLayoutComponent,
    children: publicRoutes
  },
  {
    path: 'site',
    component: SiteAgendamentoLayoutComponent,
    children: [
      // { path: 'home', component: SiteAgendamentoHomeComponent, canActivate: [StartSessionGuard] },
      { path: ':id', component: SiteAgendamentoHomeComponent },
    ]
  },
  { path: 'auth/start-session', component: StartSessionComponent },
  {
    path: 'private',
    component: PrivateLayoutComponent,
    canActivate: [SessionGuard],
    children: PrivateRouters
  },

  { path: '**', redirectTo: '/', pathMatch: 'full' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
