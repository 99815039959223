<input
  #inputFile hidden type="file" [(ngModel)]="file" (change)="uploadFile($event)"
  accept="image/*"
>
<div class="container mt-4">
  <div class="row">
    <div class="col-12">
      <div class="card">
        <div class="card-header">
          <h4 class="card-title">Produto</h4>
        </div>
        <div class="card-body">
          <div class="row">
            <div class="col-xl-3">
              <div class="row">
                <div class="col-12">
                  <img class="rounded" style="max-width: 100%" [src]="entity?.imageUrl || 'assets/images/default/default-placeholder.png'">
                </div>
              </div>
              <div class="row">
                <div class="col-12 d-inline-flex justify-content-center">
                  <button (click)="inputFile.click()" class="btn btn-sm btn-success m-1">Alterar</button>
                  <button class="btn btn-sm btn-danger m-1">Remover</button>
                </div>
              </div>
            </div>
            <div class="col-xl-9">
              <form [formGroup]="form">
                <div class="row">
                  <div class="col-4">
                    <label class="form-label">nome</label>
                    <input class="form-control" formControlName="nome">
                  </div>
                  <div class="col-4">
                    <label class="form-label">categorias</label>
                    <select formControlName="categoria" class="form-select">
                      <option value="" disabled>Selecionar categoria</option>
                      <option *ngFor="let item of listCategorias" [value]="item.id">{{item.nome}}</option>
                    </select>
                  </div>
                  <div class="col-4">
                    <label class="form-label">valor</label>
                    <input class="form-control" formControlName="valor">
                  </div>
                </div>

                <div class="row">
                  <label class="form-label">Descrição</label>
                  <div class="col-12">
                    <textarea formControlName="descricao" class="form-control"></textarea>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
        <div class="card-body">
          <ficha-produto [complementos]="complementos"></ficha-produto>
        </div>
        <div class="card-footer">
          <button crud-salvar class="btn btn-success">Salvar</button>
        </div>
      </div>
    </div>
  </div>

</div>
