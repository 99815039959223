import { Injectable } from '@angular/core';
import { Socket, io } from 'socket.io-client';
import {environment} from "../../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class WebsocketService {
  public socket!: Socket;

  constructor() {
    this.connect();
  }

  public connect() {
    this.socket = io(environment.websocket);
    this.socket.on('connect', () => {
      console.log('connected socket.io')
    });
  }

  public emit(eventName: string, data: any) {
    this.socket.emit(eventName, data);
  }

  public disconnect() {
    this.socket.disconnect();
  }
}
