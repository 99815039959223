import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FoodCategoriasMainComponent } from './food-categorias-main/food-categorias-main.component';
import { FoodCategoriasInsertEditComponent } from './food-categorias-insert-edit/food-categorias-insert-edit.component';
import {CdkDynamicTableModule, DgPaginatorModule, DgTableModule} from "@datagrupo/dg-ng-util";
import {RouterModule} from "@angular/router";
import {ReactiveFormsModule} from "@angular/forms";
import {DgCrudModule} from "@datagrupo/dg-crud";



@NgModule({
  declarations: [
    FoodCategoriasMainComponent,
    FoodCategoriasInsertEditComponent
  ],
  imports: [
    CommonModule,
    DgPaginatorModule,
    DgTableModule,
    CdkDynamicTableModule,
    RouterModule,
    ReactiveFormsModule,
    DgCrudModule
  ]
})
export class FoodCategoriasModule { }
