import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {PrivateLayoutComponent} from './private-layout/private-layout.component';
import {PrivateHeaderComponent} from './sub-components/private-header/private-header.component';
import {RouterModule} from "@angular/router";
import {DgSidebarMenuModule} from "@datagrupo/dg-ng-util";
import {MatIconModule} from "@angular/material/icon";


@NgModule({
  declarations: [
    PrivateLayoutComponent,
    PrivateHeaderComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    DgSidebarMenuModule,
    MatIconModule
  ]
})
export class PrivateLayoutModule {
}
