import {Component, EventEmitter, OnInit, Output, ViewChild} from '@angular/core';
import {DgModalComponent} from "@datagrupo/dg-ng-util";
import {HttpService} from "../../../../../../services/http-service/http.service";
import {ESTEIRA_PEDIDOS_CHANGE_STATUS} from "../../../../../../core/endpoints";
import {replaceEndpointParams} from "../../../../../../helpers/endpoints-helpers";
import {VendaEntity} from "../../../venda.entity";
import {statusPedidoType} from "../../../../../../enums/status-pedido.enum";

@Component({
  selector: 'modal-esteira-pedido',
  templateUrl: './modal-esteira-pedido.component.html',
  styleUrls: ['./modal-esteira-pedido.component.scss']
})
export class ModalEsteiraPedidoComponent implements OnInit {

  @ViewChild('modal') modal!: DgModalComponent;

  entity: VendaEntity | undefined

  constructor(private http: HttpService) {
  }

  ngOnInit(): void {
  }

  open(data: VendaEntity) {
    this.entity = data;
    this.modal.open()
  }

  close() {
    this.modal.close()
  }

  clear() {
    this.entity = undefined;
  }

  changeStatus(status: statusPedidoType) {
    if (!this.entity?.id) return;

    this.http.put(
      replaceEndpointParams(ESTEIRA_PEDIDOS_CHANGE_STATUS, this.entity?.id), {status}).subscribe(
      resp => {
        window.dispatchEvent(new CustomEvent('update-table-esteira', {detail: this.entity?.status}))
        this.close();
      })
  }

}
