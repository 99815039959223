import { Injectable } from '@angular/core';
import {AbstractSessionService} from "@datagrupo/dg-ng";
import {TokenService} from "../token/token.service";

interface userSession {
  id?: number | string;
  perfil?: tipoPerfil;
  estabalecimento?: number | string;
}
// interface para os dados do expediente
export interface Expediente {
  "id": string,
  "createdAt": string,
  "deleteAt": string | null,
  "dataInicio": string,
  "dataFim": string | null
}

export declare type tipoPerfil = 'ESTABELECIMENTO' | 'CLIENTE'

@Injectable({
  providedIn: 'root'
})
export class SessionService extends AbstractSessionService {

  user: userSession = {};
  expedienteAberto: Expediente | null | undefined = null;

  constructor(public token: TokenService) {
    super(token)
  }

  override checkPerfil(perfil: tipoPerfil | (tipoPerfil)[]): boolean {
    return super.checkPerfil(perfil);
  }

  setUser(): void {
    let token = this.token.abrirToken();
    this.user.id = token.sub;
    this.user.perfil = token.perfil
    this.user.estabalecimento = token.subEstab
  }
}
