import {Injectable} from '@angular/core';
import {statusPedidoEntregaType, statusPedidoType} from "../../../../../enums/status-pedido.enum";
import {
  ESTEIRA_PEDIDOS,
  ESTEIRA_PEDIDOS_CHANGE_STATUS_ARRAY,
} from "../../../../../core/endpoints";
import {HttpService} from "../../../../../services/http-service/http.service";

@Injectable({
  providedIn: 'root'
})
export class FoodEsteiraPedidoService {

  list_status_pedido: {
    EmAnalise: any[],
    Producao: any[],
    Pronto: any[],
    preparandoEntrega: any[],
    ACaminho: any[],
    Entrege: any[],
  } = {
    EmAnalise: [],
    Producao: [],
    Pronto: [],
    preparandoEntrega: [],
    ACaminho: [],
    Entrege: [],
  }

  constructor(private http: HttpService) {
  }

  /**
   * metodo de mudança de status
   * @param list
   * @param previousStatus
   * @param nextStatus
   */
  changeStatusArray(list: (string | number)[], previousStatus: statusPedidoType, nextStatus: statusPedidoType) {
    this.http.put(
      ESTEIRA_PEDIDOS_CHANGE_STATUS_ARRAY, {list, previousStatus, nextStatus}).subscribe(
      resp => {
      })
  }

  /**
   * metodo de selecionar por evento click
   * @param list
   * @param id
   * @param event
   */
  clickSelectPedidoEvent(list: any[], id: string, event?: MouseEvent) {
    if (event) {
      if (event.ctrlKey) {
        const index = list.findIndex(item => item.id == id)

        if (index != -1) {
          list[index]['selectedItem'] = !list[index]['selectedItem'];
        }
      } else {
        list.map(item => {
          if (item['selectedItem']) delete item['selectedItem']
        })
      }
    }
  }

  /**
   * busca as tabelas
   * @param status
   */
  getTables(status: statusPedidoType | statusPedidoEntregaType) {
    this.http.get(ESTEIRA_PEDIDOS, {params: {status}}).subscribe(resp => {
      this.list_status_pedido[this.accessNameListByStatus(status)] = resp.data;
    })
  }

  /**
   * Retorna o nome da lista com base no estatus
   * @param status
   */
  accessNameListByStatus(status: statusPedidoType | statusPedidoEntregaType)
    : 'EmAnalise' | 'Producao' | 'Pronto' | 'preparandoEntrega' | 'ACaminho' | 'Entrege' {
    const labelTables: {
      'EM_ANALISE': 'EmAnalise',
      'PRODUCAO': 'Producao',
      'PRONTO': 'Pronto',
      'CAMINHO': 'ACaminho',
      'ENTREGUE': 'Entrege',
      'PREPARO_ENTREGA': 'preparandoEntrega',
      'ENTREGA_ANDAMENTO': 'ACaminho',
    } = {
      'EM_ANALISE': 'EmAnalise',
      'PRODUCAO': 'Producao',
      'PRONTO': 'Pronto',
      'CAMINHO': 'ACaminho',
      'ENTREGUE': 'Entrege',
      'PREPARO_ENTREGA': 'preparandoEntrega',
      'ENTREGA_ANDAMENTO': 'ACaminho',
    }
    return labelTables[status]
  }

  /**
   * retorna a lista com base no status
   * @param status
   */
  accessListByStatus(status: statusPedidoType): any[] {
    return this.list_status_pedido[this.accessNameListByStatus(status)]
  }
}
