import {AbstractEntity2, CrudEntity} from "@datagrupo/dg-crud";
import {DynamicColumn, DynamicTableEntity} from "@datagrupo/dg-ng-util";
import { FOOD_CATEGORIA} from "../../../../core/endpoints";
import {environment} from "../../../../../environments/environment";

@CrudEntity({
  api: {
    path: environment.apiUrl,
    context: FOOD_CATEGORIA
  }
})
@DynamicTableEntity({
  api: {
    path: environment.apiUrl,
    context: FOOD_CATEGORIA
  }
})
export class FoodCategoriaEntity extends AbstractEntity2 {

  constructor(
    nome?: string,
    tipo?: string
  ) {
    super();
    this.nome = nome;
    this.tipo = tipo;
  }

  @DynamicColumn({ headerName: 'Nome' })
  nome: string | undefined
  @DynamicColumn({ headerName: 'Tipo' })
  tipo: string | undefined
}
