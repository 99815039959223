import {AbstractEntity2, CrudEntity} from "@datagrupo/dg-crud";
import {environment} from "../../../../environments/environment";
import {PRODUTO} from "../../../core/endpoints";
import {DynamicTableEntity, DynamicColumn} from "@datagrupo/dg-ng-util";


@CrudEntity({
  api: {
    path: environment.apiUrl,
    context: PRODUTO
  }
})
@DynamicTableEntity({
  api: {
    path: environment.apiUrl,
    context: PRODUTO
  }
})
export class ProdutoEntity extends AbstractEntity2 {

  constructor(
    nome?: string,
    valor?: string,
    categorias?: string
  ) {
    super();
    this.nome = nome;
    this.valor = valor;
    this.categorias = categorias;
  }

  @DynamicColumn({ headerName: 'nome' })
  nome: string | undefined;
  @DynamicColumn({ headerName: 'valor' })
  valor: string | undefined;
  @DynamicColumn({ headerName: 'categorias' })
  categorias: string | undefined;
}
