<dg-modal #modal [size]="!form.value?.entregador ? 'sm' : 'lg'" (afterClose)="clear()">
  <div modal-body>
    <form [formGroup]="form">
      <div class="row">
        <div class="col-12">
          <label class="form-label">Entregador</label>
          <select formControlName="entregador" class="form-select">
            <option value="" disabled>Selecione um entregador</option>
            <option
              *ngFor="let item of listEntregadores"
              [value]="item.id"
            >
              {{item.nome}}
            </option>
          </select>
        </div>
      </div>
    </form>
    <div class="row mt-3" style="height: 20rem" *ngIf="!!form.value?.entregador">
      <div class="col-5" style="height: 100%;">
        <div class="card card-content-status-pedido-esteira" style="height: 100%;">
          <div class="card-header">
            <h6 class="card-title">Pronto</h6>
          </div>
          <div
            class="card-body"
            cdkDropList
            id="list-pedidosProntos"
            #pedidosProntos="cdkDropList"
            [cdkDropListData]="listPedidosProntos"
            [cdkDropListConnectedTo]="[pedidosSelecionados]"
            (cdkDropListDropped)="drop($event)"
          >
            <div class="card col-12" *ngFor="let pedido of listPedidosProntos; let index = index">
              <div
                class="card-header d-inline-flex justify-content-between"
                cdkDrag
              >
                <h6 class="card-title">{{pedido?.cliente?.nome || '--'}}</h6>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-2">
        buttons
      </div>
      <div class="col-5" style="height: 100%;">
        <div class="card card-content-status-pedido-esteira" style="height: 100%;">
          <div class="card-header">
            <h6 class="card-title">Pronto</h6>
          </div>
          <div
            class="card-body"
            cdkDropList
            id="list-pedidosSelecionados"
            #pedidosSelecionados="cdkDropList"
            [cdkDropListData]="entity.vendas"
            [cdkDropListConnectedTo]="[pedidosProntos]"
            (cdkDropListDropped)="drop($event)"
          >
            <div class="card col-12" *ngFor="let pedido of entity.vendas; let index = index">
              <div
                class="card-header d-inline-flex justify-content-between"
                cdkDrag
              >
                <h6 class="card-title">{{pedido?.cliente?.nome || '--'}}</h6>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div modal-footer>
    <button (click)="close()" class="btn btn-secondary m-1">Sair</button>
    <button (click)="save()" class="btn btn-success m-1">Salvar</button>
  </div>
</dg-modal>
