import { Routes } from '@angular/router';
import {HomeComponent} from "./home/home/home.component";
import {EstabelecimentoMainComponent} from "./estabelecimento/estabelecimento-main/estabelecimento-main.component";
import {
  EstabelecimentoInsertEditComponent
} from "./estabelecimento/estabelecimento-insert-edit/estabelecimento-insert-edit.component";
import {ColaboradorMainComponent} from "./colaborador/colaborador-main/colaborador-main.component";
import {ColaboradorInsertEditComponent} from "./colaborador/colaborador-insert-edit/colaborador-insert-edit.component";
import {CategoriaMainComponent} from "./categoria/categoria-main/categoria-main.component";
import {CategoriaInsertEditComponent} from "./categoria/categoria-insert-edit/categoria-insert-edit.component";
import {
  ServicosAgendaveisMainComponent
} from "./servicos-agendaveis/servicos-agendaveis-main/servicos-agendaveis-main.component";
import {
  ServicosAgendaveisInsertEditComponent
} from "./servicos-agendaveis/servicos-agendaveis-insert-edit/servicos-agendaveis-insert-edit.component";
import {ProdutosMainComponent} from "./produto/produtos-main/produtos-main.component";
import {ProdutosInsertEditComponent} from "./produto/produtos-insert-edit/produtos-insert-edit.component";
import {ClientesMainComponent} from "./clientes/clientes-main/clientes-main.component";
import {ClientesInsertEditComponent} from "./clientes/clientes-insert-edit/clientes-insert-edit.component";
import {AgendaComponent} from "./agenda/agenda/agenda.component";
import {AcompanhamentoComponent} from "./agenda/acompanhamento/acompanhamento.component";
import {FoodProdutosMainComponent} from "./food/food-produtos/food-produtos-main/food-produtos-main.component";
import {
  FoodProdutosInsertEditComponent
} from "./food/food-produtos/food-produtos-insert-edit/food-produtos-insert-edit.component";
import {FoodCategoriasMainComponent} from "./food/food-categorias/food-categorias-main/food-categorias-main.component";
import {
  FoodCategoriasInsertEditComponent
} from "./food/food-categorias/food-categorias-insert-edit/food-categorias-insert-edit.component";
import {EmpresaMainComponent} from "./empresa/empresa-main/empresa-main.component";
import {EmpresaInsertEditComponent} from "./empresa/empresa-insert-edit/empresa-insert-edit.component";
import {
  FoodEsteiraPedidosMainComponent
} from "./food/food-esteira-pedidos/food-esteira-pedidos-main/food-esteira-pedidos-main.component";
import {EntregadorMainComponent} from "./entregador/entregador-main/entregador-main.component";
import {
  FormaPagamentoMainComponent
} from "./cadastros/forma-pagamento/forma-pagamento-main/forma-pagamento-main.component";

export const PrivateRouters: Routes = [
  { path: 'home', component: HomeComponent },

  { path: 'empresa', component: EmpresaMainComponent },
  {
    path: 'empresa',
    children: [
      { path: ':id', component: EmpresaInsertEditComponent }
    ]
  },

  { path: 'food/esteira-pedidos', component: FoodEsteiraPedidosMainComponent },

  { path: 'food/cadastros/produtos', component: FoodProdutosMainComponent },
  {
    path: 'food/cadastros/produtos',
    children: [
      { path: ':id', component: FoodProdutosInsertEditComponent }
    ]
  },

  { path: 'food/cadastros/categorias', component: FoodCategoriasMainComponent },
  {
    path: 'food/cadastros/categorias',
    children: [
      { path: ':id', component: FoodCategoriasInsertEditComponent }
    ]
  },

  { path: 'cadastros/estabelecimento', component: EstabelecimentoMainComponent },
  {
    path: 'cadastros/estabelecimento',
    children: [
      { path: ':id', component: EstabelecimentoInsertEditComponent }
    ]
  },

  { path: 'cadastros/colaboradores', component: ColaboradorMainComponent },
  {
    path: 'cadastros/colaboradores',
    children: [
      { path: ':id', component: ColaboradorInsertEditComponent }
    ]
  },

  { path: 'cadastros/categorias', component: CategoriaMainComponent },
  {
    path: 'cadastros/categorias',
    children: [
      { path: ':id', component: CategoriaInsertEditComponent }
    ]
  },

  { path: 'cadastros/produtos', component: ProdutosMainComponent },
  {
    path: 'cadastros/produtos',
    children: [
      { path: ':id', component: ProdutosInsertEditComponent }
    ]
  },

  { path: 'cadastros/servicos-agendaveis', component: ServicosAgendaveisMainComponent },
  {
    path: 'cadastros/servicos-agendaveis',
    children: [
      { path: ':id', component: ServicosAgendaveisInsertEditComponent }
    ]
  },

  { path: 'cadastros/clientes', component: ClientesMainComponent },
  {
    path: 'cadastros/clientes',
    children: [
      { path: ':id', component: ClientesInsertEditComponent }
    ]
  },

  { path: 'cadastros/entregador', component: EntregadorMainComponent },

  { path: 'cadastros/forma-pagamento', component: FormaPagamentoMainComponent },


  { path: 'agenda', component: AgendaComponent },
  { path: 'acompanhamento', component: AcompanhamentoComponent },
];
