import {Component, OnInit, ViewChild} from '@angular/core';
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {InsertEditConfig2} from "@datagrupo/dg-crud";
import {HttpHelpersService} from "../../../services/http-helpers/http-helpers.service";
import {HttpService} from "../../../services/http-service/http.service";
import Swal from "sweetalert2";
import {Router} from "@angular/router";
import {SIGNUP} from "../../../core/endpoints";
import {MatStepper} from "@angular/material/stepper";
import {TokenService} from "../../../services/token/token.service";
import {SessionService} from "../../../services/session/session.service";

@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.scss']
})
export class SignupComponent implements OnInit {

  @ViewChild('stepper') stepper!: MatStepper;
  @ViewChild('stepperConfig') stepperConfig!: MatStepper;

  formasPagamento: string[] = ['DINHEIRO'];

  formEmpresa = new FormGroup({
    nome: new FormControl('', [Validators.required]),
    link: new FormControl('', [Validators.required]),
    cep: new FormControl('', [Validators.required]),
    logradouro: new FormControl('', [Validators.required]),
    numero: new FormControl('', [Validators.required]),
    complemento: new FormControl(''),
    bairro: new FormControl(''),
    cidade: new FormControl(''),
    estado: new FormControl(''),
  });

  formUser = new FormGroup({
    nome: new FormControl('', [Validators.required]),
    email: new FormControl('', [Validators.required]),
    senha: new FormControl('', [Validators.required]),
    confirmSenha: new FormControl('', [Validators.required]),
  });

  formLayout = new FormControl('', [Validators.required])

  public form = new FormGroup({
    nome: new FormControl('', [Validators.required]),
    telefone: new FormControl('', [Validators.required]),
    instagram: new FormControl('', [Validators.required]),
    facebook: new FormControl('', [Validators.required]),
    cep: new FormControl('', [Validators.required]),
    logradouro: new FormControl('', [Validators.required]),
    numero: new FormControl('', [Validators.required]),
    complemento: new FormControl(''),
    bairro: new FormControl(''),
    cidade: new FormControl(''),
    estado: new FormControl(''),
  })

  formHorariosFuncionamento = new FormGroup({
    domingoStatus: new FormControl(false),
    domingoAbertura: new FormControl(''),
    domingoFechamento: new FormControl(''),

    segundaStatus: new FormControl(false),
    segundaAbertura: new FormControl(''),
    segundaFechamento: new FormControl(''),

    tercaStatus: new FormControl(false),
    tercaAbertura: new FormControl(''),
    tercaFechamento: new FormControl(''),

    quartaStatus: new FormControl(false),
    quartaAbertura: new FormControl(''),
    quartaFechamento: new FormControl(''),

    quintaStatus: new FormControl(false),
    quintaAbertura: new FormControl(''),
    quintaFechamento: new FormControl(''),

    sextaStatus: new FormControl(false),
    sextaAbertura: new FormControl(''),
    sextaFechamento: new FormControl(''),

    sabadoStatus: new FormControl(false),
    sabadoAbertura: new FormControl(''),
    sabadoFechamento: new FormControl(''),
  })

  listLayouts = [
    {
      nome: 'FOOD1',
      image: 'assets/images/layouts-exemple/food1.png',
      value: 'FOOD1'
    }
  ]

  constructor(
    private httpHelper: HttpHelpersService,
    private service: HttpService,
    private router: Router,
    private token: TokenService,
    private session: SessionService
  ) {
    this.formEmpresa.controls['cep'].valueChanges.subscribe((resp: any) => {
      this.httpHelper.getCep(resp, value => {
        this.formEmpresa.patchValue({
          logradouro: value.logradouro,
          bairro: value.bairro,
          cidade: value.localidade,
          estado: value.uf,
        })
      })
    })
  }

  ngOnInit(): void {
    this.formHorariosFuncionamento.controls['domingoStatus'].valueChanges.subscribe((resp: any) => {
      this.formHorariosFuncionamento.controls['domingoAbertura'][!!resp ? 'enable' : 'disable']()
      this.formHorariosFuncionamento.controls['domingoFechamento'][!!resp ? 'enable' : 'disable']()
    })
    this.formHorariosFuncionamento.controls['segundaStatus'].valueChanges.subscribe((resp: any) => {
      this.formHorariosFuncionamento.controls['segundaAbertura'][!!resp ? 'enable' : 'disable']()
      this.formHorariosFuncionamento.controls['segundaFechamento'][!!resp ? 'enable' : 'disable']()
    })
    this.formHorariosFuncionamento.controls['tercaStatus'].valueChanges.subscribe((resp: any) => {
      this.formHorariosFuncionamento.controls['tercaAbertura'][!!resp ? 'enable' : 'disable']()
      this.formHorariosFuncionamento.controls['tercaFechamento'][!!resp ? 'enable' : 'disable']()
    })
    this.formHorariosFuncionamento.controls['quartaStatus'].valueChanges.subscribe((resp: any) => {
      this.formHorariosFuncionamento.controls['quartaAbertura'][!!resp ? 'enable' : 'disable']()
      this.formHorariosFuncionamento.controls['quartaFechamento'][!!resp ? 'enable' : 'disable']()
    })
    this.formHorariosFuncionamento.controls['quintaStatus'].valueChanges.subscribe((resp: any) => {
      this.formHorariosFuncionamento.controls['quintaAbertura'][!!resp ? 'enable' : 'disable']()
      this.formHorariosFuncionamento.controls['quintaFechamento'][!!resp ? 'enable' : 'disable']()
    })
    this.formHorariosFuncionamento.controls['sextaStatus'].valueChanges.subscribe((resp: any) => {
      this.formHorariosFuncionamento.controls['sextaAbertura'][!!resp ? 'enable' : 'disable']()
      this.formHorariosFuncionamento.controls['sextaFechamento'][!!resp ? 'enable' : 'disable']()
    })
    this.formHorariosFuncionamento.controls['sabadoStatus'].valueChanges.subscribe((resp: any) => {
      this.formHorariosFuncionamento.controls['sabadoAbertura'][!!resp ? 'enable' : 'disable']()
      this.formHorariosFuncionamento.controls['sabadoFechamento'][!!resp ? 'enable' : 'disable']()
    })
    this.formHorariosFuncionamento.patchValue({
      segundaStatus: true,
      tercaStatus: true,
      quartaStatus: true,
      quintaStatus: true,
      sextaStatus: true,
      sabadoStatus: true,
    })
  }

  salvar() {
    if (this.formEmpresa.invalid) {
      this.formEmpresa.markAllAsTouched();
      this.stepper.selectedIndex = 0;
      return;
    }
    if (this.formUser.invalid) {
      this.formUser.markAllAsTouched();
      this.stepper.selectedIndex = 1;
      this.stepperConfig.selectedIndex = 0;
      return;
    }
    if (this.formasPagamento.length <= 0) {
      this.formUser.markAllAsTouched();
      this.stepper.selectedIndex = 1;
      this.stepperConfig.selectedIndex = 1;
      return;
    }

    const usuario = this.formUser.value;
    const empresa = this.formEmpresa.value;
    const template = this.formLayout.value;
    const formasPagamento = this.formasPagamento;

    const data = {
      usuario,
      empresa: {
        ...empresa,
        template, formasPagamento
      }
    }

    this.service.post(SIGNUP, data).subscribe(
      resp => {
        Swal.fire({
          icon: 'success',
          title: 'Seja bem vindo',
          timer: 3000
        }).then(() => {
          window.localStorage.setItem('accessToken', resp.data.access_token)
          window.location.href = '/auth/start-session'
        })
      },
      error => {
        Swal.fire({
          icon: "error",
          title: error.error.message
        }).then()
      })
  }

  clickSelect(value: string) {
    const index = this.formasPagamento.findIndex(forma => forma == value);

    if (index >= 0) {
      this.formasPagamento.splice(index, 1)
    } else {
      this.formasPagamento.push(value)
    }
  }

  isSelected(value: string) {
    return this.formasPagamento.findIndex(forma => forma == value) >= 0;
  }

  next() {
    switch (this.stepper.selectedIndex) {
      case 0:
        if (this.formEmpresa.invalid) {
          this.formEmpresa.markAllAsTouched();
          return;
        }

        this.stepper.next();
        break;
      case 1:
        switch (this.stepperConfig.selectedIndex) {
          case 0:
            if (this.formUser.invalid) {
              this.formUser.markAllAsTouched();
              return;
            }

            this.stepperConfig.next();
            break;
          case 1:
            if (this.formasPagamento.length <= 0) {
              return;
            }

            this.stepperConfig.next();
            break;
          case 2:
            this.stepper.next()
            break;
        }
        break;
    }
  }

  back() {
    switch (this.stepper.selectedIndex) {
      case 0:
        break;
      case 1:
        switch (this.stepperConfig.selectedIndex) {
          case 0:
            this.stepper.previous()
            break;
          case 1:
            this.stepperConfig.previous();
            break;
          case 2:
            this.stepperConfig.previous()
            break;
        }
        break;
      case 2:
        this.stepper.previous()
        break;
    }
  }

  disableNext(): boolean {
    switch (this.stepper?.selectedIndex) {
      case 0:
        return this.formEmpresa.invalid;
      case 1:
        switch (this.stepperConfig.selectedIndex) {
          case 0:
            return this.formUser.invalid;
          case 1:
            return this.formasPagamento.length <= 0
          case 2:
            return true;
        }
        break;
      case 2:
        return this.formLayout.invalid
      default:
        return false
    }

    return false
  }
}
